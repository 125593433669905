import {Route, Routes} from "react-router-dom"
import Layout from "./layout/Layout"
import HomePage from "./pages/HomePage"
import AppRedirectContainer from "./pages/AppRedirect/AppRedirectContainer"
import LuckyWheelContainer from "./pages/LuckyWheel/LuckyWheelContainer"
import LuckyWheelRewardContainer from "./pages/LuckyWheelReward/LuckyWheelRewardContainer"
import MoonFitAstarRaffleContainer from "./pages/WeeklyRaffle/MoonFitAstarRaffleContainer"
import WeeklyRaffleContainer from "./pages/WeeklyRaffle/WeeklyRaffleContainer"
import MoonFitApesRaffleContainer from "./pages/WeeklyRaffle/MoonFitApesRaffleContainer"
import WelcomeRaffleContainer from "./pages/WeeklyRaffle/WelcomeRaffleContainer"
import MoonFitMonesRaffleContainer from "./pages/WeeklyRaffle/MoonFitMonesRaffleContainer"
import LuckyWheelBeyondContainer from "./pages/LuckyWheelBeyond/LuckyWheelContainer"
import MoonFitAjunaRaffleContainer from "./pages/WeeklyRaffle/MoonFitAjunaRaffleContainer"
import MoonfitSummerFitsnapContainer from "./pages/WeeklyRaffle/MoonfitSummerFitsnapContainer"
import MoonfitAlgemContainer from "./pages/WeeklyRaffle/MoonfitAlgemContainer"
import MoonfitHashkeyContainer from "./pages/WeeklyRaffle/MoonfitHashkeyContainer"
import MoonfitStarfishContainer from "./pages/WeeklyRaffle/MoonfitStarfishContainer"
import LuckyWheelParisDot from "./pages/LuckyWheelParisDot/LuckyWheelContainer"
import LuckyWheelPolkadot from "./pages/LuckyWheelPolkadot/LuckyWheelContainer"
import MoonfitAstridContainer from "./pages/WeeklyRaffle/MoonfitAstridContainer"
import MoonfitYuliverseContainer from "./pages/WeeklyRaffle/MoonfitYuliverseRaffleContainer"
import UserWelcomeContainer from "./pages/UserWelcome/UserWelcomeContainer"
import MoonfitLunarFestivalContainer from "./pages/WeeklyRaffle/MoonFitLunarFestivalContainer"
import MoonfitChristmasChallengeContainer from "./pages/WeeklyRaffle/MoonFitChristmasChallengeContainer"
import MoonfitValentineChallengeContainer from "./pages/WeeklyRaffle/MoonFitValentineChallengeContainer"

const router = (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/welcome" element={<UserWelcomeContainer/>} />
            <Route path="/weekly-raffle" element={<WeeklyRaffleContainer />} />
            <Route path="/lucky-wheel" element={<LuckyWheelContainer />} />
            <Route path="/lucky-wheel-beyond" element={<LuckyWheelBeyondContainer />} />
            <Route path="/lucky-wheel-paris-dot" element={<LuckyWheelParisDot />} />
            <Route path="/lucky-wheel-polkadot-decoded-2023" element={<LuckyWheelPolkadot />} />
            <Route path="/lucky-wheel-reward" element={<LuckyWheelRewardContainer />} />
            <Route path="/moonfit-astar-raffle" element={<MoonFitAstarRaffleContainer />} />
            <Route path="/moonfit-glmr-apes-raffle" element={<MoonFitApesRaffleContainer />} />
            <Route path="/welcome-raffle" element={<WelcomeRaffleContainer />} />
            <Route path="/moonfit-mones-raffle" element={<MoonFitMonesRaffleContainer />} />
            <Route path="/moonfit-ajuna-raffle" element={<MoonFitAjunaRaffleContainer />} />
            <Route path="/advent_summer_fitsnap_challenge_raffle_1" element={<MoonfitSummerFitsnapContainer />} />
            <Route path="/advent_summer_fitsnap_challenge_raffle_2" element={<MoonfitSummerFitsnapContainer />} />
            <Route path="/advent_summer_fitsnap_challenge_raffle_3" element={<MoonfitSummerFitsnapContainer />} />
            <Route path="/advent_summer_fitsnap_challenge_raffle_4" element={<MoonfitSummerFitsnapContainer />} />
            <Route path="/moonfit-x-algem-challenge" element={<MoonfitAlgemContainer />} />
            <Route path="/moonfit-x-hashkey-did-challenge" element={<MoonfitHashkeyContainer />} />
            <Route path="/moonfit-x-starfish-finance-challenge" element={<MoonfitStarfishContainer />} />
            <Route path="/moonfit-x-astriddao-challenge" element={<MoonfitAstridContainer />} />
            <Route path="/moonfit-x-yuliverse-challenge" element={<MoonfitYuliverseContainer />} />
            <Route path="/lunar-gaming-festival-thanksgiving-challenge" element={<MoonfitLunarFestivalContainer />} />
            <Route path="/christmas-challenge" element={<MoonfitChristmasChallengeContainer/>}/>
            <Route path="/valentine-challenge-2024" element={<MoonfitValentineChallengeContainer/>}/>
        </Route>
        <Route path="/app-redirect" element={<AppRedirectContainer />} />
    </Routes>
)
export default router
