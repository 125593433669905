import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import mfr from "../../assets/images/rewards/mfr.png"
import mfg from "../../assets/images/rewards/mfg.png"
import freeSpin from "../../assets/images/rewards/free-spin.png"
import raffleTicket from "../../assets/images/rewards/raffle-ticket.png"
import uncommonBox from "../../assets/images/rewards/uncommon-moonbox.png"
import commonBox from "../../assets/images/rewards/common-moonbox.png"
import rareBox from "../../assets/images/rewards/rare-moonbox.png"
import mythicalBox from "../../assets/images/rewards/mythical-moonbox.png"
import glmr from "../../assets/images/rewards/glmr.png"
import check from "../../assets/images/rewards/check.svg"
import collect from "../../assets/images/rewards/collect.svg"
import spin from "../../assets/images/rewards/spin.svg"
import {CloseOutlined} from "@ant-design/icons"
import MoonboxOutSlotModal from "./MoonboxOutSlotModal"

const LuckyWheelRewardContainer = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const navigate = useNavigate()
    const [renderText, setRenderText] = useState("")
    const [renderReward, setRenderReward] = useState(null)
    const [renderButton, setRenderButton] = useState(null)
    const [open, setOpen] = useState(false)
    const value = params.get("value")
    const type = params.get("reward_type")
    const is_pending = params.get("is_pending")
    const need_buy_slot = params.get("need_buy_slot")
    const buy_slot_fee = params.get("buy_slot_fee")
    const lucky_box_id = params.get("lucky_box_id")
    const rarity = params.get("rarity")
    const unit = params.get("unit")
    useEffect(() => {
        if (!type) {
            return navigate("/lucky-wheel")
        }
        _renderUI(value, type)
    }, [])

    const onCollect = (type = "") => {
        if (type === "raffle") {
            return navigate("/")
        }
        if (type === "MoonBox") {
            if (is_pending === "true" && need_buy_slot === "true") {
                return setOpen(true)
            }
            navigate("/lucky-wheel")
        } else {
            navigate("/lucky-wheel")
        }
    }

    const fetchRarity = () => {
        let reward = {
            title: "Common",
            moonbox: commonBox,
        }
        switch (rarity) {
            case "R2":
                reward = {
                    title: "Uncommon",
                    moonbox: uncommonBox,
                }
                break
            case "R3":
                reward = {
                    title: "Rare",
                    moonbox: rareBox,
                }
                break
            case "R4":
                reward = {
                    title: "Mythical",
                    moonbox: mythicalBox,
                }
                break
            default: break
        }
        return reward
    }

    const _renderUI = (value, type) => {
        switch (type) {
            case "MoonBox":
                const {title, moonbox} = fetchRarity()
                setRenderText(`1 ${title} ${unit}!`)
                setRenderReward(<img src={moonbox} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={() => onCollect("MoonBox")}>
                        <img className="mb-1 mr-2" src={collect} alt="" />
                        Collect
                    </button>
                )
                break
            case "MFR":
                setRenderText(`${value} ${unit}`)
                setRenderReward(<img src={mfr} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={onCollect}>
                        <img className="mb-1 mr-2" src={collect} alt="" />
                        Collect
                    </button>
                )
                break
            case "MFG":
                setRenderText(`${value} ${unit}`)
                setRenderReward(<img src={mfg} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={onCollect}>
                        <img className="mb-1 mr-2" src={collect} alt="" />
                        Collect
                    </button>
                )
                break
            case "GLMR":
                setRenderText(`${value} ${unit}`)
                setRenderReward(<img src={glmr} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={onCollect}>
                        <img className="mb-1 mr-2" src={collect} alt="" />
                        Collect
                    </button>
                )
                break
            case "WeeklyRaffleTicket":
                setRenderText(`${value} ${unit}`)
                setRenderReward(<img src={raffleTicket} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={() => onCollect("raffle")}>
                        <img className="mb-1 mr-2" src={check} alt="" />
                        View Raffle
                    </button>
                )
                break
            case "FreeSpin":
                setRenderText(`${value} ${unit}`)
                setRenderReward(<img src={freeSpin} alt="" />)
                setRenderButton(
                    <button type="button" className="button button-primary py-3" onClick={onCollect}>
                        <img className="mb-1 mr-2" src={spin} alt="" />
                        Spin Now
                    </button>
                )
                break
            default: break
        }
    }

    const toggle = () => setOpen(!open)
    const closeModal = () => {
        return navigate("/lucky-wheel")
    }

    return (
        <div className="lw-reward-container text-center">
            <MoonboxOutSlotModal open={open} toggle={toggle} buy_slot_fee={buy_slot_fee} lucky_box_id={lucky_box_id} />
            <div className="lw-btn-back">
                <span className="text-white" onClick={() => closeModal()}>
                    <CloseOutlined />
                </span>
            </div>
            <div className="lw-reward-title">
                <h3 className="text-[48px] leading-[3rem] font-normal text-gradient">YOU WON</h3>
                <p className="text-[18px] text-white font-semibold">{renderText}</p>
            </div>
            <div className="lw-reward-wrapper flex flex-col justify-between mx-auto">
                <div></div>
                <div className="lw-reward">{renderReward}</div>
                <div className="lw-button">{renderButton}</div>
            </div>
        </div>
    )
}

export default LuckyWheelRewardContainer
