import {Avatar} from "antd"
import React, {useEffect, useState} from "react"

const WinnerList = (props) => {
    const {histories} = props
    const [winnerIndex, setWinnerIndex] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            const el = document.getElementById(`winner_${winnerIndex}`)
            if (el) {
                el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
                setWinnerIndex(winnerIndex === histories.length - 1 ? 0 : winnerIndex + 1)
            }
        }, 2000)
    }, [histories, winnerIndex])

    const _renderWinnerText = (user, type, rewardName, value) => {
        let text = null
        switch (type) {
            case "MoonBox":
            case "MerchandiseTShirt":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#A16BD8] font-semibold">{`${value} ${rewardName}`}</span>
                    </p>
                )
                break
            case "MFR":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#E4007B] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "MFG":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#4CCBC9] font-semibold">{`${value} ${rewardName}`}</span>
                    </p>
                )
                break
            case "GLMR":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "WeeklyRaffleTicket":
            case "MerchandiseCup":
            case "MerchandiseCap":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#EFAA5D] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "MoonFitAstarDegensTicket":
            case "MerchandiseToteBag":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#48C8F0] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "MoonFitGLMRApesTicket":
            case "MerchandiseKeychain":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#A5D990] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "MoonFitMonesTicket":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#FAEB68] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "MoonFitAjunaNetworkTicket":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#3D89E3] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            case "FreeSpin":
                text = (
                    <p className="normal-case text-[11px] font-normal ml-2">
                        <span className="font-semibold">{user}</span> won{" "}
                        <span className="text-[#4CCBC9] font-semibold">{`${value} ${rewardName}`}</span>{" "}
                    </p>
                )
                break
            default:
                break
        }
        return text
    }

    return (
        <div className="lw-list-winners">
            <div className="lw-winners-wrapper">
                {histories.map((history, index) => {
                    const {user, rewards} = history
                    return (
                        <div key={index} className="flex flex-nowrap justify-center" id={`winner_${index}`}>
                            <div className="lw-winner no-event">
                                <div className="winner-avatar mr-1">
                                    <Avatar size={24} src={user.avatar} />
                                    <div className="sub-avatar">
                                        <img src={rewards[0].icon} alt="" />
                                    </div>
                                </div>
                                {_renderWinnerText(user.name, rewards[0].type, rewards[0].unit, rewards[0].value)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WinnerList
