import React, {useEffect, useState} from "react"
import banner from "../../assets/images/background/ajuna-banner.png"
import ajunaReward from "../../assets/images/background/ajuna-reward.png"
import lwEvent from "../../assets/images/background/raffle-lw.png"
import dailyEvent from "../../assets/images/background/raffle-daily.png"
import raffleTicket from "../../assets/images/icons/ajuna-ticket.svg"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import numb3 from "../../assets/images/materials/numb3.png"
import users from "../../assets/images/materials/users.png"
import wallet from "../../assets/images/materials/wallet.png"
import btnHowToPlay from "../../assets/images/materials/how-to-play.png"
import btnExclusive from "../../assets/images/materials/exclusive.png"
import btnWinners from "../../assets/images/materials/winners.png"
import {Avatar, Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons"
import CountdownComponent from "../../components/Countdown"
import {getRaffleData} from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import moment from "moment/moment"
import {accessFromWeeklyRaffle, gotoMobilePage, refreshAccessToken} from "../../utils/webview"
import {useNavigate} from "react-router-dom"
import WidgetBar from "../../components/WidgetBar"

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

const MoonFitAjunaRaffleContainer = () => {
    const navigate = useNavigate()
    const [ticket, setTicket] = useState(0)
    const [winners, setWinners] = useState([])
    const [nftWinner, setNftWinner] = useState([])
    const [raffle, setRaffle] = useState({})
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
    })
    const [loadingChangeRound, setLoadingChangeRound] = useState(false)

    useEffect(() => {
        fetchWeeklyRaffe()
    }, [])

    const fetchWeeklyRaffe = async () => {
        try {
            setLoadingChangeRound(true)
            const res = await getRaffleData("special-raffle/ajuna_network")
            const {data} = res
            setLoadingChangeRound(false)
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                finishTime: data.weekly_raffle.finish_time,
            })
            updateState(data)
            const listWinner = [...data?.weekly_raffle?.metadata?.beasts, ...data?.weekly_raffle?.metadata?.rareNFTs]
            setListWinners(data?.weekly_raffle?.winners, data.user_id)
            setListNftWinner(listWinner, data.user_id)
        } catch (error) {
            refreshAccessToken()
        }
    }

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    const setListNftWinner = (winners, userId) => {
        if (Array.isArray(winners) && winners.length > 0) {
            const winner = winners.find((winner) => winner.user_id === userId)
            const newWinners = [...winners]
            if (winner) {
                const winnerIndex = newWinners.findIndex((winner) => winner.user_id === userId)
                if (winnerIndex !== -1) {
                    newWinners.splice(winnerIndex, 1)
                }
                newWinners.unshift(winner)
            }
            setNftWinner(newWinners)
        }
    }

    const setListWinners = (winners, userId) => {
        if (Array.isArray(winners) && winners.length > 0) {
            const winner = winners.find((winner) => winner.id === userId)
            const randomNumber = randomFromInterval(0, winners.length - 18)
            const newWinners = winners.slice(randomNumber, randomNumber + 17)
            if (winner && winner.id) {
                const winnerIndex = newWinners.findIndex((winner) => winner.id === userId)
                if (winnerIndex !== -1) {
                    newWinners.splice(winnerIndex, 1)
                }
                newWinners.unshift(winner)
            }
            const filteredArray = newWinners.filter(itemA => !nftWinner.some(itemB => itemB.user_id === itemA.id))
            setWinners(filteredArray.slice(0, 17))
        }
    }

    const randomFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const onClickSpinNow = () => {
        accessFromWeeklyRaffle()
        return navigate("/lucky-wheel")
    }

    const userWinBeast = nftWinner.find((winner) => winner?.user_id === newestRound.userId) || {}

    
    return (
        <div className="weekly-raffle-wrapper ajuna">
            <div className="weekly-raffle-container ajuna-event">
                <WidgetBar redirectTo="Weekly_Raffle" />
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="weekly-raffle-content">
                    <div className="weekly-raffle-banner-title">
                        {newestRound.prize ? (
                            <div className="text-center">
                                <p className="text-[20px] race-sport-font text-[#B8AC9E] leading-[1.75rem] uppercase beast-reward mb-1">
                                    3 Rare AAA NFTS
                                </p>
                                <p className="text-[20px] race-sport-font text-[#EFAA5D] leading-[1.75rem] uppercase beast-reward mb-1">
                                    2 Common Beast NFT
                                </p>
                                <p className="text-[20px] race-sport-font text-[#B8AC9E] leading-[1.75rem] uppercase beast-reward mb-1">
                                    + $300 worth of $Baju
                                </p>
                                <p className="text-[20px] race-sport-font text-[#E4007B] leading-[1.75rem] uppercase beast-reward mb-1">
                                    + 300 $GLMR
                                </p>
                                <span>TO BE WON!</span>
                                <p className="text-white text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                                    You have{" "}
                                    <span className="text-[#B8AC9E] mx-1">
                                        {newestRound.tickets > 1
                                            ? `${newestRound.tickets} tickets`
                                            : `${newestRound.tickets} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} style={{width: "16px"}} alt="" /> this
                                    round
                                </p>
                            </div>
                        ) : null}

                        {!newestRound.isFinish && (
                            <div className="daily-countdown text-center mt-5 mb-5">
                                <p className="text-[16px] font-semibold leading-7 mb-1">
                                    Join MoonFit x Awesome Ajuna Avatars campaign, complete{" "}
                                    <span className="text-[#4CCBC9]">Daily Missions</span> or spin{" "}
                                    <span className="text-[#F0489F]">The Lucky Wheel</span>
                                </p>
                                <p className="text-[11px] font-semibold leading-5">
                                    to earn AAA Tickets & a chance to win the prize pot!
                                </p>
                                <div className="flex flex-nowrap justify-center no-event">
                                    <CountdownComponent
                                        date={moment(newestRound.finishTime).utc().valueOf()}
                                        completedCallback={null}
                                    />
                                </div>
                                <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">
                                    until the next draw
                                </p>
                            </div>
                        )}
                    </div>
                    {!newestRound.isFinish && (
                        <div className="events grid grid-cols-2 gap-2">
                            <div className="lw-event" onClick={() => onClickSpinNow()}>
                                <img src={lwEvent} alt="" />
                            </div>
                            <div className="daily-event" onClick={() => gotoMobilePage("daily_bonus")}>
                                <img src={dailyEvent} alt="" />
                            </div>
                        </div>
                    )}

                    {newestRound.isFinish && (
                        <div className="finished-round-container">
                            <div className="floating-btn">
                                <img src={btnWinners} alt="" />
                            </div>
                            <div className="round-winner mt-2">
                                {userWinBeast.user_id && (
                                    <div className="nft-winner mt-5 mb-5">
                                        <div
                                            className={`${
                                                userWinBeast.rarity_name === "Common"
                                                    ? "common-winner-border"
                                                    : "ajuna-winner-border"
                                            }`}
                                        >
                                            <div className="winner-beast">
                                                <img src={userWinBeast?.user?.avatar} alt="" />
                                            </div>
                                        </div>
                                        <div className="winner-info text-center mt-2">
                                            <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                                {userWinBeast?.user?.name}
                                            </p>
                                            <span className="text-[11px] text-[#90829D]">
                                                {userWinBeast.rarity_name === "Common"
                                                    ? "Common Beast NFT"
                                                    : "Rare AAA NFT"}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="nft-winners mt-5 mb-5">
                                    {nftWinner
                                        .filter((winner) => winner.user_id !== newestRound.userId)
                                        .map((winner) => (
                                            <div className="common-winner mt-3">
                                                <div
                                                    className={`${
                                                        winner.rarity_name === "Common"
                                                            ? "common-winner-border"
                                                            : "ajuna-winner-border"
                                                    }`}
                                                >
                                                    <div className="winner-beast">
                                                        <img src={winner?.user?.avatar} alt="" />
                                                    </div>
                                                </div>
                                                <div className="winner-info text-center mt-2">
                                                    <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                                        {winner?.user?.name}
                                                    </p>
                                                    <span className="text-[11px] text-[#90829D]">
                                                        {winner.rarity_name === "Common"
                                                            ? "Common Beast NFT"
                                                            : "Rare AAA NFT"}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <div className="winner-list my-3">
                                    {loadingChangeRound ? (
                                        <div className="text-center my-3">
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <ul
                                            className={`grid gap-1 ${
                                                winners.length > 0 ? "grid-cols-2" : "grid-cols-1"
                                            }`}
                                        >
                                            {winners.length > 0 ? (
                                                <>
                                                    {winners.map((winner, index) => (
                                                        <li
                                                            key={`${index}_${new Date()}`}
                                                            className={`${
                                                                winner.id === newestRound.userId ? "active" : ""
                                                            }`}
                                                        >
                                                            <div className="winner-border">
                                                                <Avatar size={20} src={winner.avatar} />
                                                                <span className="text-[13px] ml-1 font-medium">
                                                                    {winner.name}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    <li className="ml-1">
                                                        <span
                                                            className="text-[13px] font-poppins text-[#A16BD8] font-semibold"
                                                            style={{lineHeight: "20px"}}
                                                        >
                                                            +26 more...
                                                        </span>
                                                    </li>
                                                </>
                                            ) : (
                                                <li className="text-center">
                                                    <span className="text-[13px] font-medium">
                                                        There are no winners this round
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="prize-pot">
                                <p className="text-[12px] font-semibold mb-1">PRIZE POT</p>
                                <p className="text-[16px] leading-[1.625rem] race-sport-font text-[#B8AC9E] mb-1">
                                    3 RARE AAA NFTs
                                </p>
                                <p className="text-[16px] leading-[1.625rem] race-sport-font text-[#EFAA5D] mb-1">
                                    +2 COMMON BEAST NFT
                                </p>
                                <p className="text-[16px] leading-[1.625rem] race-sport-font text-[#B8AC9E] mb-1">
                                    + $300 worth of BAJU
                                </p>
                                <p className="text-[16px] leading-[1.625rem] race-sport-font text-[#E4007B] mb-1">
                                    + $300 $GLMRS
                                </p>
                                <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                    Total players this round:{" "}
                                    <span className="text-[#4CCBC9]">
                                        {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                    </span>
                                </p>
                                <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                    You have{" "}
                                    <span className="text-[#B8AC9E] mx-1">
                                        {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} style={{width: "16px"}} alt="" /> this
                                    round
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="instruction-container">
                        {/* <div className="button button-box-shadow w-[10.625rem]">How to play</div> */}
                        <div className="floating-btn">
                            <img src={btnHowToPlay} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb1} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">EARN TICKETS</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Join <span className="text-white">MoonFit x Awesome Ajuna Avatars campaign</span>,{" "}
                                    <span className="text-[#4CCBC9]">Complete Daily Missions</span> or try your luck at{" "}
                                    <span className="text-[#E4007B]">The Lucky Wheel</span> to earn{" "}
                                    <span className="text-[#B8AC9E]">AAA Tickets</span>.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb2} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">WAIT FOR THE DRAW</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    The total number of winners for this Raffle is 50.
                                </p>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Out of the 50 winners, <span className="text-[#ffffff]">45 selected winners</span>{" "}
                                    will share a reward pool of <span className="text-[#E4007B]">300 $GLMR</span> tokens
                                    + <span className="text-[#B8AC9E]">$300 worth of $BAJU</span> tokens.
                                </p>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Out of the 50 winners, <span className="text-[#ffffff]">5 selected winners</span>{" "}
                                    will receive <span className="text-[#EFAA5D]">2 Common Beast NFTs</span> and{" "}
                                    <span className="text-[#B8AC9E]">3 Rare AAA NFTs</span>.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb3} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">CHECK FOR PRIZES</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Once the round’s over, come back to the page and check to see if you’ve won!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="instruction-container exclusive">
                        <div className="floating-btn">
                            <img src={btnExclusive} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                For verified Ajuna Network members only.
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={wallet} alt="" />
                                </div>
                                <span className="text-[16px] race-sport-font font-semibold">01</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Join campaigns by Ajuna Network to receive redeemable codes. Then redeem the codes
                                    in <span className="text-[#E4007B]">MoonFit</span> app to receive up to{" "}
                                    <span className="text-[#B8AC9E]">10 AAA tickets</span>.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={users} alt="" />
                                </div>
                                <span className="text-[16px] race-sport-font font-semibold">02</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Join <span className="text-[#B8AC9E]">Ajuna Clan</span> and send message to DAN for
                                    additional <span className="text-[#B8AC9E]">2 vip codes - 20 AAA tickets</span>. You
                                    must be a member of the Ajuna Network Clan until the drawing takes place to keep
                                    your 20 tickets valid.
                                </p>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Failure to comply with these rules will result in invalid tickets.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoonFitAjunaRaffleContainer
