import React, { useEffect,useState } from "react"
import caretLeft from "../../assets/images/icons/caret-left.svg"
import stepOne from "../../assets/images/welcome/step-1.png"
import stepTwo from "../../assets/images/welcome/step-2.png"
import stepThree from "../../assets/images/welcome/step-3.png"
import stepFour from "../../assets/images/welcome/step-4.png"
import {ACTION_TYPES} from "../../common/constants"
import {postActionToMobile} from "../../utils/webview"
import FingerprintJS from '@fingerprintjs/fingerprintjs'



export default function UserWelcome() {
    const onPostAction = () => {
        postActionToMobile(ACTION_TYPES.BUTTON_GO_BACK, true)
    }

    return (
            <div className="welcome flex flex-col space-y-12 mt-8">
                <div className="welcome-header flex items-start justify-between text-center">
                    {/* <div className="lw-btn-back" onClick={() => onPostAction()}>
                        <img src={caretLeft} alt="" />
                    </div> */}
                    <div className="intro-text">
                        <p>Connect your wallet and enjoin all features</p>
                    </div>
                    {/* <div className="overlay"></div> */}
                </div>
                <div className="welcome-container w-full flex flex-col gap-[2.75rem] md:gap-[3.25rem]">
                    <div className="welcome-step relative">
                        <div className="welcome-step-header text-center absolute top-0 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                            <p>STEP 01</p>
                        </div>
                        <div className="welcome-step-picture w-full flex justify-center">
                            <img src={stepOne} alt="" />
                        </div>
                    </div>
                    <div className="welcome-step relative">
                        <div className="welcome-step-header text-center absolute top-0 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                            <p>STEP 02</p>
                        </div>
                        <div className="welcome-step-picture w-full flex justify-center">
                            <img src={stepTwo} alt="" />
                        </div>
                    </div>
                    <div className="welcome-step flex justify-between relative">
                        <div className="welcome-step-header text-center absolute top-0 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                            <p>STEP 03</p>
                        </div>
                        <div className="welcome-step-picture w-full flex justify-center">
                            <img src={stepThree} alt="" />
                        </div>
                    </div>
                    <div className="welcome-step relative">
                        <div className="welcome-step-header text-center absolute top-0 left-1/2  transform -translate-x-1/2 -translate-y-1/2">
                            <p>STEP 04</p>
                        </div>
                        <div className="welcome-step-picture w-full flex justify-center">
                            <img src={stepFour} alt="" />
                        </div>
                    </div>
                </div>
                <div className="lw-btn-back flex flex-col items-center justify-between" onClick={() => onPostAction()}>
                        <button className="btn-skip">Skip</button>
                    </div>

            </div>
    )
}

