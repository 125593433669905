const getConfigs = () => {
    const env = process.env.REACT_APP_ENV
    switch (env) {
        case "development":
            return {
                API_URL: "https://api-dev.moonfit.xyz/v1",
                APP_URI: "",
            }
        case "production":
            return {
                API_URL: "https://api-prod.moonfit.xyz/v1",
                APP_URI: "",
            }
        default:
            return {
                API_URL: "https://api-dev.moonfit.xyz/v1",
                APP_URI: "",
            }
    }
}

export const COMMON_CONFIGS = getConfigs()
