import React, {useEffect, useState} from "react"
import banner from "../../assets/images/background/weekly-raffle-title.png"
import lwEvent from "../../assets/images/background/raffle-lw.png"
import dailyEvent from "../../assets/images/background/raffle-daily.png"
import raffleTicket from "../../assets/images/icons/ticket-no-border.svg"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import numb3 from "../../assets/images/materials/numb3.png"
import CaretLeft from "../../assets/images/icons/caret-circle-left.svg"
import CaretRight from "../../assets/images/icons/caret-circle-right.svg"
import btnHowToPlay from "../../assets/images/materials/how-to-play.png"
import btnFinishedRound from "../../assets/images/materials/finished-rounds.png"
import {Avatar, Spin} from "antd"
import {LoadingOutlined} from "@ant-design/icons"
import CountdownComponent from "../../components/Countdown"
import {getWeekylyRaffleData} from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import moment from "moment/moment"
import {accessFromWeeklyRaffle, gotoMobilePage, refreshAccessToken} from "../../utils/webview"
import {useNavigate} from "react-router-dom"
import WidgetBar from "../../components/WidgetBar"

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

const WeeklyRaffleContainer = () => {
    const navigate = useNavigate()
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [currentRound, setCurrentRound] = useState(null)
    const [lastRound, setLastRound] = useState(null)
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
    })
    const [loadingChangeRound, setLoadingChangeRound] = useState(false)

    useEffect(() => {
        fetchCurrentRound()
    }, [])

    const fetchCurrentRound = async () => {
        try {
            const res = await getWeekylyRaffleData("")
            const {data, success} = res
            if (success) {
                setNewestRound({
                    userId: data.user_id,
                    prize: data.weekly_raffle.prize,
                    tickets: data.tickets,
                    finishTime: data.weekly_raffle.finish_time,
                })
                data.round > 1 && fetchWeeklyRaffe(data.round - 1)
            }
        } catch (error) {
            refreshAccessToken()
        }
    }
    const fetchWeeklyRaffe = async (round = "") => {
        try {
            setLoadingChangeRound(true)
            const res = await getWeekylyRaffleData(round)
            const {data} = res
            setLoadingChangeRound(false)
            updateState(data)
        } catch (error) {
            refreshAccessToken()
        }
    }

    const updateState = (data) => {
        setCurrentRound(data.round)
        setRaffle(data.weekly_raffle)
        setLastRound(data.last_round)
        setTicket(data.tickets)
    }

    const onChangeRound = (round = 1, action = "prev") => {
        if (action === "prev") {
            fetchWeeklyRaffe(round - 1)
        } else {
            fetchWeeklyRaffe(round + 1)
        }
    }

    const onClickSpinNow = () => {
        accessFromWeeklyRaffle()
        return navigate("/lucky-wheel")
    }

    return (
        <div className="weekly-raffle-wrapper">
            <div className="weekly-raffle-container">
                <WidgetBar redirectTo="Weekly_Raffle" />
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="weekly-raffle-content">
                    <div className="weekly-raffle-banner-title">
                        {newestRound.prize ? (
                            <div className="text-center">
                                <p className="text-pink-glow text-[24px]">{newestRound.prize} $GLMR</p>
                                <span className="font-medium">TO BE WON !</span>
                                <p className="text-white text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                                    You have{" "}
                                    <span className="text-[#E8AC76] mx-1">
                                        {newestRound.tickets > 1
                                            ? `${newestRound.tickets} tickets`
                                            : `${newestRound.tickets} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        ) : null}

                        <div className="daily-countdown text-center mt-5 mb-5">
                            <p className="text-[16px] font-semibold leading-7 mb-1">
                                Complete <span className="text-[#4CCBC9]">7 daily checkins in a row</span> or{" "}
                                <span className="text-[#F0489F]">spin The Lucky Wheel</span>
                            </p>
                            <p className="text-[11px] font-semibold leading-5">
                                to earn a Raffle Tickets & a chance to win prize pot!
                            </p>
                            <div className="flex flex-nowrap justify-center no-event">
                                <CountdownComponent
                                    date={moment(newestRound.finishTime).utc().valueOf()}
                                    completedCallback={null}
                                />
                            </div>
                            <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">
                                until the next draw
                            </p>
                        </div>
                    </div>
                    <div className="events grid grid-cols-2 gap-2">
                        <div className="lw-event" onClick={() => onClickSpinNow()}>
                            <img src={lwEvent} alt="" />
                            {/* <div className="button text-white bg-[#F0489F]">SPIN NOW</div> */}
                        </div>
                        <div className="daily-event" onClick={() => gotoMobilePage("daily_bonus")}>
                            <img src={dailyEvent} alt="" />
                            {/* <div className="button bg-[#A5D990]">CLAIM NOW</div> */}
                        </div>
                    </div>
                    {
                        lastRound > 1 && <div className="finished-round-container">
                            {/* <div className="button button-box-shadow w-[13.125rem]">Finished rounds</div> */}
                            <div className="floating-btn">
                                <img src={btnFinishedRound} alt="" />
                            </div>
                            <div className="current-round flex flex-nowrap justify-center items-center mt-3">
                                <div
                                    className={`prev${currentRound === 1 || loadingChangeRound ? " disabled" : ""}`}
                                    onClick={() => onChangeRound(currentRound, "prev")}
                                >
                                    <img src={CaretLeft} alt="" />
                                </div>
                                <div className="current-date text-center">
                                    <p className="text-[12px] font-semibold">ROUND</p>
                                    <h2 className="text-[#E4007B]">{currentRound}</h2>
                                </div>
                                <div
                                    className={`next${
                                        (lastRound > 1 && currentRound === lastRound - 1) || loadingChangeRound
                                            ? " disabled"
                                            : ""
                                    }`}
                                    onClick={() => onChangeRound(currentRound, "next")}
                                >
                                    <img src={CaretRight} alt="" />
                                </div>
                            </div>
                            <div className="text-center">
                                <span className="text-[11px] text-[#90829D] font-medium">
                                    {/* {moment(raffle.finish_time).format("lll")} */}
                                    {raffle?.metadata?.drawn_at
                                        ? `Drawn ${moment(raffle?.metadata?.drawn_at).format("lll")}`
                                        : ""}
                                </span>
                            </div>

                            <div className="round-winner mt-2">
                                <span className="text-[12px] font-semibold">WINNER</span>
                                <div className="winner-list my-3">
                                    {loadingChangeRound ? (
                                        <div className="text-center my-3">
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <ul
                                            className={`grid gap-1 ${
                                                raffle?.winners?.length > 0 ? "grid-cols-2" : "grid-cols-1"
                                            }`}
                                        >
                                            {raffle?.winners?.length > 0 ? (
                                                raffle?.winners?.map((winner, index) => (
                                                    <>
                                                        <li
                                                            key={`${index}_${new Date()}`}
                                                            className={`${
                                                                winner.id === newestRound.userId ? "active" : ""
                                                            }`}
                                                        >
                                                            <div className="winner-border">
                                                                <Avatar size={20} src={winner.avatar} />
                                                                <span className="text-[13px] ml-1 font-medium">
                                                                    {winner.name}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))
                                            ) : (
                                                <li className="text-center">
                                                    <span className="text-[13px] font-medium">
                                                        There are no winners this round
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="prize-pot">
                                <p className="text-[12px] font-semibold">PRIZE POT</p>
                                <p className="text-[24px] text-pink-glow mb-5">{raffle.prize || 0} $GLMR</p>
                                <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                    Total players this round:{" "}
                                    <span className="text-[#4CCBC9]">
                                        {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                    </span>
                                </p>
                                <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                    You have{" "}
                                    <span className="text-[#E8AC76] mx-1">
                                        {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        </div>
                    }

                    <div className="instruction-container">
                        {/* <div className="button button-box-shadow w-[10.625rem]">How to play</div> */}
                        <div className="floating-btn">
                            <img src={btnHowToPlay} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb1} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">EARN TICKETS</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Complete 7-day Login Bonus mission or try your luck at spin The Lucky Wheel to earn
                                    Raffle Tickets.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb2} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">WAIT FOR THE DRAW</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    An amount of around 100 $GLMR will fill the prize pot and there will be one weekly
                                    draw. There will be eight (8) winners each week.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb3} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">CHECK FOR PRIZES</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Once the round’s over, come back to the page and check to see if you’ve won!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeeklyRaffleContainer
