import { LoadingOutlined } from "@ant-design/icons"
import moment from "moment/moment"
import React, {useCallback, useEffect, useState} from "react"
import {useLocation, useNavigate } from "react-router-dom"
import banner from "../../assets/images/background/summer-fitsnap-banner.png"
import summerFitsnapEvent from "../../assets/images/background/summer-fitsnap-event.png"
import raffleTicket from "../../assets/images/icons/summer-fitsnap-ticket.svg"
import btnHowToPlay from "../../assets/images/materials/how-to-join.png"
import btnClaim from "../../assets/images/materials/btn-claim.png"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import numb3 from "../../assets/images/materials/numb3.png"
import btnWinners from "../../assets/images/materials/winners.png"
import CountdownComponent from "../../components/Countdown"
import WidgetBar from "../../components/WidgetBar"
import { getRaffleData } from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import { openMobileBrowser, refreshAccessToken } from "../../utils/webview"

const MoonfitSummerFitsnapContainer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
        winner: null,
    })

    const fetchAdventRaffle = useCallback(async () => {
        try {
            const res = await getRaffleData(`special-raffle/${location.pathname.substring(1)}`)
            const {data} = res
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                finishTime: data.weekly_raffle.finish_time,
                winner: data.weekly_raffle.winners[0] || null,
            })
            updateState(data)
        } catch (error) {
            refreshAccessToken()
        }
    }, [location])

    useEffect(() => {
        fetchAdventRaffle()
    }, [fetchAdventRaffle])

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    const summerFitsnapEventUrl =
        process.env.REACT_APP_ENV === "development"
            ? `https://dev-event.moonfit.xyz/special-event/summer-fitsnap-challenge`
            : `https://event.moonfit.xyz/special-event/summer-fitsnap-challenge`

    const handleOpenLink = (event) => {
        event.preventDefault();
        // Perform custom actions or navigation using React Router
        window.open("https://forms.gle/FrqDr6j9HcwWumtV7", "_blank")
    }

    return (
        <div className="weekly-raffle-wrapper">
            <div className="weekly-raffle-container summer-fitsnap-raffle">
                <WidgetBar redirectTo="Weekly_Raffle" />
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="weekly-raffle-content">
                    <div className="weekly-raffle-banner-title">
                        <div className="text-center">
                            <h3 className="text-summer-fitsnap-title">Special Raffle</h3>
                            <p className="text-white text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                                You have{" "}
                                <span className="text-[#0BBEFE] mx-1">
                                    {newestRound.tickets > 1
                                        ? `${newestRound.tickets} tickets`
                                        : `${newestRound.tickets} ticket`}
                                </span>{" "}
                                <img className="mr-1" src={raffleTicket} alt="" /> this week
                            </p>
                        </div>

                        <div className="daily-countdown text-center mt-5 mb-5">
                            <p className="text-[16px] font-semibold leading-7 mb-1">
                                Embrace the <span className="text-[#0BBEFE]">Advanced Challenge</span> to grab a chance
                                to get an exclusive <span className="text-[#E4007B]">Hawaiian T-shirt.</span>
                            </p>
                            <div className="flex flex-nowrap justify-center no-event">
                                <CountdownComponent
                                    date={moment(newestRound.finishTime).utc().valueOf()}
                                    completedCallback={null}
                                />
                            </div>
                            <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">
                                until the next draw
                            </p>
                        </div>
                    </div>

                    <div className="events grid">
                        <div className="lw-event">
                            <a onClick={() => openMobileBrowser(summerFitsnapEventUrl)}>
                                <img src={summerFitsnapEvent} alt="" />
                            </a>
                        </div>
                    </div>

                    {newestRound.isFinish && newestRound.winner && (
                        <div className="finished-round-container">
                            <div className="floating-btn">
                                <img src={btnWinners} alt="" />
                            </div>
                            <div className="round-winner mt-2">
                                <div className="nft-winner mt-5 mb-5">
                                    <div className="uncommon-winner-border">
                                        <div className="winner-border">
                                            <div className="winner-beast">
                                                <img src={newestRound?.winner?.avatar} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="winner-info text-center mt-2">
                                        <p className="text-[13px] text-[#ffffff] m-0">{newestRound.winner.name}</p>
                                        <span className="text-[11px] text-[#90829D]">1 Hawaiian T-shirt</span>
                                    </div>
                                </div>
                            </div>
                            <div className="prize-pot">
                                <p className="text-[12px] font-semibold">PRIZE POT</p>
                                <p className="text-[16px] race-sport-font text-[#E4007B] mb-3">1 Hawaiian T-shirt</p>
                                <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                    Total players this round:{" "}
                                    <span className="text-[#4CCBC9]">
                                        {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                    </span>
                                </p>
                                <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                    You have{" "}
                                    <span className="text-[#0BBEFE] mx-1">
                                        {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                                {newestRound?.userId === newestRound?.winner?.id && (
                                    <a onClick={() => openMobileBrowser("https://forms.gle/FrqDr6j9HcwWumtV7")}>
                                        <img src={btnClaim} alt="" />
                                    </a>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="instruction-container">
                        <div className="floating-btn">
                            <img src={btnHowToPlay} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                We have an exciting <span className="text-[#0BBEFE]">Summer FitSnap Raffle</span>{" "}
                                happening every Sunday, where you have a chance to win a{" "}
                                <span className="text-white">fantastic gift.</span>
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb1} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">EARN TICKETS</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Earn <span className="text-[#0BBEFE]">1 raffle ticket</span> for completing each
                                    daily task.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb2} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">WAIT FOR THE DRAW</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    For those who take on the <span className="text-[#0BBEFE]">Advanced Challenge</span>{" "}
                                    and succeed, you'll receive <span className="text-white">10 raffle tickets.</span>
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb3} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">CHECK FOR PRIZES</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    MoonFit will pick <span className="text-white">one lucky winner every Sunday</span>,
                                    who will receive an exclusive{" "}
                                    <span className="text-[#E4007B]">Hawaiian T-shirt</span>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoonfitSummerFitsnapContainer
