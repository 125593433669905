import {Avatar} from "antd"
import moment from "moment/moment"
import React, {useCallback, useEffect, useState} from "react"
import christmasBanner from "../../assets/images/background/christmas-challenge-banner.png"
import christmasHeader from "../../assets/images/background/christmas-challenge-header.png"
import btnHowToPlay from "../../assets/images/materials/how-to-join.png"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import btnWinners from "../../assets/images/materials/winners.png"
import CountdownComponent from "../../components/Countdown"
import WidgetBar from "../../components/WidgetBar"
import {getRaffleData} from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import {openMobileBrowser, refreshAccessToken} from "../../utils/webview"

const MoonfitChristmasChallengeContainer = () => {
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [nftWinner, setNftWinner] = useState([])
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
        winner: null,
    })
    const [tShirtWinners, setTShirtWinners] = useState([])

    const fetchAdventRaffle = useCallback(async () => {
        try {
            const res = await getRaffleData(`special-raffle/christmas-challenge`)
            const {data} = res
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                finishTime: data.weekly_raffle.finish_time,
                winner: data.weekly_raffle.winners[0] || null,
            })
            updateState(data)
            const listWinner = [...data?.weekly_raffle?.metadata?.beasts]
            const listTShirtWinner = [...data?.weekly_raffle?.metadata?.tShirtWinner]
            setListNftWinner(listWinner, listTShirtWinner, data.user_id)
        } catch (error) {
            refreshAccessToken()
            console.log({error})
        }
    }, [])

    const setListNftWinner = (winners, secondListWinner, userId) => {
        if (Array.isArray(winners) && winners.length > 0) {
            const winner = winners.find((winner) => {
                if (winner.user_id === userId) {
                    return {...winners, thisUserInTop: true}
                }
            })
            const newWinners = [...winners]
            if (winner) {
                const winnerIndex = newWinners.findIndex((winner) => winner.user_id === userId)
                if (winnerIndex !== -1) {
                    newWinners.splice(winnerIndex, 1)
                }
                newWinners.unshift(winner)
            }
            setNftWinner(newWinners)
        }
        if (Array.isArray(secondListWinner) && secondListWinner.length > 0) {
            const winner = secondListWinner.find((winner) => {
                if (winner.user_id === userId) {
                    return {...winners, thisUserInTop: true}
                }
            })
            const newWinners = [...secondListWinner]
            if (winner) {
                const winnerIndex = newWinners.findIndex((winner) => winner.user_id === userId)
                if (winnerIndex !== -1) {
                    newWinners.splice(winnerIndex, 1)
                }
                newWinners.unshift(winner)
            }
            setTShirtWinners(newWinners)
        }
    }

    useEffect(() => {
        fetchAdventRaffle()
    }, [fetchAdventRaffle])

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    const christmasEventUrl =
        process.env.REACT_APP_ENV === "development"
            ? `https://dev-event.moonfit.xyz/special-event/christmas-challenge`
            : `https://event.moonfit.xyz/special-event/christmas-challenge`

    const userWinBeast = nftWinner.find((winner) => winner?.user_id === newestRound.userId) || {}
    const listWinBeast = nftWinner.filter((winner) => winner.user_id !== newestRound.userId)

    return (
        <div className="weekly-raffle-container christmas-raffle">
            <WidgetBar redirectTo="Weekly_Raffle" />
            <div className="weekly-raffle-content">
                <div className="weekly-raffle-banner christmas-raffle">
                    {/* <h2 className="text-christmas-title">Lunar Gaming Festival</h2>
                    <p className="text-christmas-small-text">& Thanksgiving Challenge</p>
                     */}
                    <img src={christmasHeader} alt="" />
                </div>
                <div className="text-center">
                    <p className="text-[#FFF] text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                        You have{" "}
                        <span className="text-[#E967E0] font-medium mx-1">
                            {newestRound.tickets > 1
                                ? `${newestRound.tickets} tickets`
                                : `${newestRound.tickets} ticket`}
                        </span>{" "}
                        this week
                    </p>
                </div>
                <div className="weekly-raffle-banner-title">
                    <div className="daily-countdown text-center mt-5 mb-5">
                        <p className="text-white text-[16px] font-semibold leading-7 mb-1">
                            Embrace the Lunar Gaming Festival x Xmas Challenge to grab a chance to get exclusive
                            rewards!
                        </p>
                        <p className="text-[#FFB044] text-[16px] font-semibold leading-7 mb-0">
                            5 MoonBeast NFTs +
                        </p>
                        <p className="text-[#FFB044] text-[16px] font-semibold leading-7 mb-0">
                            5 MoonFit Sweatshirts
                        </p>
                        <div className="flex flex-nowrap justify-center no-event">
                            <CountdownComponent
                                date={moment(newestRound.finishTime).utc().valueOf()}
                                completedCallback={null}
                            />
                        </div>
                        <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">until the next draw</p>
                    </div>
                </div>

                <div className="events grid">
                    <div className="lw-event">
                        <a onClick={() => openMobileBrowser(christmasEventUrl)}>
                            <img src={christmasBanner} alt="" />
                        </a>
                    </div>
                </div>

                {newestRound.isFinish && newestRound.winner && (
                    <div className="finished-round-container">
                        <div className="floating-btn">
                            <img src={btnWinners} alt="" />
                        </div>

                        <div className="round-winner mt-2">
                            {userWinBeast.user_id && (
                                <div className="nft-winner mt-5 mb-5">
                                    <div
                                        className={`${
                                            userWinBeast.rarity_name === "Common" ? "common-winner-border" : ""
                                        }`}
                                    >
                                        <div className="winner-beast">
                                            <img src={userWinBeast?.user?.avatar} alt="" />
                                        </div>
                                    </div>
                                    <div className="winner-info text-center mt-2">
                                        <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                            {userWinBeast?.user?.name}
                                        </p>
                                        <span className="text-[11px] text-[#90829D]">
                                            {userWinBeast.rarity_name === "Common" ? "Common Beast NFT" : ""}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className={`nft-winners mt-5 mb-5 ${"triangle-layout"}`}>
                                {listWinBeast.map((winner, index) => (
                                    <div className={`common-winner mt-3 ${index == 0 ? "first" : ""}`}>
                                        <div
                                            className={`${
                                                winner.rarity_name === "Common" ? "common-winner-border" : ""
                                            }`}
                                        >
                                            <div className="winner-beast">
                                                <img src={winner?.user?.avatar} alt="" />
                                            </div>
                                        </div>
                                        <div className="winner-info text-center mt-2">
                                            <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                                {winner?.user?.name}
                                            </p>
                                            <span className="text-[11px] text-[#90829D]">
                                                {winner.rarity_name === "Common"
                                                    ? "Common Beast NFT"
                                                    : winner.rarity_name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="winner-list my-3">
                                <ul
                                    className={`grid gap-1 ${tShirtWinners.length > 0 ? "grid-cols-2" : "grid-cols-1"}`}
                                >
                                    {tShirtWinners.length > 0 ? (
                                        <>
                                            {tShirtWinners.map((winner, index) => (
                                                <li
                                                    key={`${index}_${new Date()}`}
                                                    className={`${
                                                        winner.user_id === newestRound.userId ? "active" : ""
                                                    }`}
                                                >
                                                    <div className="winner-border">
                                                        <Avatar size={20} src={winner.avatar} />
                                                        <span className="text-[13px] ml-1 font-medium">
                                                            {winner.name}
                                                        </span>
                                                    </div>
                                                </li>
                                            ))}
                                        </>
                                    ) : (
                                        <li className="text-center">
                                            <span className="text-[13px] font-medium">
                                                There are no winners this round
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <div className="prize-pot">
                            <p className="text-[12px] font-semibold">PRIZE POT</p>
                            <p className="text-[16px] race-sport-font text-[#EFAA5D] mb-3">5 MoonBeast NFTs</p>
                            <p className="text-[16px] race-sport-font text-[#57C2FF] mb-3">+ 5 MoonFit Sweatshirts</p>
                            {/* <p className="text-[16px] race-sport-font text-[#57C2FF] mb-3">+ random rewards</p> */}
                            <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                Total players this round:{" "}
                                <span className="text-[#4CCBC9]">
                                    {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                </span>
                            </p>
                            <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                You have{" "}
                                <span className="text-[#57C2FF] mx-1">
                                    {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                </span>{" "}
                                this round
                            </p>
                        </div>
                    </div>
                )}

                <div className="instruction-container">
                    <div className="floating-btn">
                        <img src={btnHowToPlay} alt="" />
                    </div>
                    <div className="text-center">
                        <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                            More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                        </p>
                    </div>
                    <div className="grid grid-cols-1">
                        <div className="instruction-item">
                            <div className="instruction-item-index">
                                <img src={numb1} alt="" />
                            </div>
                            <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                Download <span className="text-[#E4007B]">Moonfit app</span> & Connect your{" "}
                                <span className="text-[#ffffff]">MetaMask wallet.</span>
                            </p>
                        </div>
                        <div className="instruction-item">
                            <div className="instruction-item-index">
                                <img src={numb2} alt="" />
                            </div>
                            <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                Complete daily mandatory tasks to get daily random reward and tickets to the{" "}
                                <span className="text-[#ffffff]">Master Prize Pool</span>. The more tickets they have,
                                the more likely they are to receive rewards from{" "}
                                <span className="text-[#ffffff]">Master Prize Pool.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoonfitChristmasChallengeContainer

