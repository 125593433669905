import React, {useEffect, useState} from "react"
import lwText from "../../assets/images/background/lw-paris-dot-text.png"
import {getSpecialLuckyWheelInfo} from "../../services/LuckyWheelServices"
import {LOCALSTORAGE_KEY, getLocalStorage, setLocalStorage} from "../../utils/LocalStorage"
import {refreshAccessToken, updateGameToken} from "../../utils/webview"
import LuckyWheelModal from "./LuckyWheelModal"
import Wheel from "./Wheel"
import WinnerList from "./WinnerList"
import {decodeString} from "../../utils/decode"

const LuckyWheelParisDot = () => {
    const [openModal, setOpenModal] = useState(false)
    const [histories, setHistories] = useState([])
    const [freeSpin, setFreeSpin] = useState(0)
    const [wheelsInfo, setWheelsInfo] = useState([])
    const [user, setUser] = useState({})
    const [refetch, setRefetch] = useState(0)
    const [gameToken, setGameToken] = useState(0)
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [onKeyboardShowing, setOnKeyboardShowing] = useState(false)
    const [eventCodes, setEventCodes] = useState("")
    const [isUserClaim, setIsUserClaim] = useState(false)

    useEffect(() => {
        fetchLuckyWheelInfo()
    }, [])

    const fetchLuckyWheelInfo = async () => {
        const wheels = getLocalStorage(LOCALSTORAGE_KEY.WHEEL_REWARDS || [])
        try {
            setLoadingFetch(true)
            setWheelsInfo(JSON.parse(wheels))
            const {data} = await getSpecialLuckyWheelInfo("paris_dot_2023")
            setLoadingFetch(false)
            setHistories(data?.histories)
            setUser(data?.user || {})
            if (data?.user?.game_token) {
                setGameToken(data.user.game_token)
                updateGameToken(data.user.game_token)
            }
            if (data?.is_new_today) {
                setOpenModal(true)
            }
            if (data?.raw) {
                const jsonData = decodeString(data.raw, "paris_dot_2023")
                const parse = JSON.parse(jsonData)
                setEventCodes(parse.codes)
            }
            setIsUserClaim(data?.used)
            setFreeSpin(data?.free_spin)
            if (data?.wheels) {
                setWheelsInfo(data?.wheels || [])
                setLocalStorage(LOCALSTORAGE_KEY.WHEEL_REWARDS, JSON.stringify(data?.wheels))
            }
        } catch (error) {
            console.log(error)
            setLoadingFetch(false)
            refreshAccessToken()
        }
    }

    const toggleModal = () => {
        setOpenModal(!openModal)
    }

    return (
        <>
            <LuckyWheelModal open={openModal} toggle={toggleModal} />
            {!loadingFetch && (
                <div className={`lw-container paris-dot${onKeyboardShowing ? " show-keyboard" : ""}`}>
                    <div className="lw-text no-event">
                        <img src={lwText} alt="" />
                    </div>
                    {histories && <WinnerList histories={histories} />}
                    <Wheel
                        luckyWheel={wheelsInfo}
                        user={user}
                        freeSpin={freeSpin}
                        loadingFetch={loadingFetch}
                        refetch={refetch}
                        eventCodes={eventCodes}
                        isUserClaim={isUserClaim}
                        setRefetch={setRefetch}
                        setFreeSpin={setFreeSpin}
                        gameToken={gameToken}
                        setGameToken={setGameToken}
                        setOnKeyboardShowing={setOnKeyboardShowing}
                        setIsUserClaim={setIsUserClaim}
                    />
                </div>
            )}
        </>
    )
}

export default LuckyWheelParisDot
