import React, {useEffect, useState} from "react"
import mfg from "../../assets/images/icons/mfg.svg"
import mfr from "../../assets/images/icons/mfr-border.svg"
import oMFG from "../../assets/images/icons/oMFG.svg"
import mfrWhite from "../../assets/images/icons/mfr-white.svg"
import raffleTicket from "../../assets/images/icons/raffle-ticket.svg"
import astarTicket from "../../assets/images/icons/astar-ticket.png"
import apesTicket from "../../assets/images/icons/apes-ticket.png"
import monesTicket from "../../assets/images/icons/mones-ticket.png"
import ajunaTicket from "../../assets/images/icons/ajuna-ticket.svg"
import spinTicket from "../../assets/images/icons/spin-ticket.svg"
import moonboxSlot from "../../assets/images/icons/moonbox-slot.svg"
import gift from "../../assets/images/icons/gift.svg"
import lottery from "../../assets/images/icons/lottery.svg"
import {spinWheel} from "../../services/LuckyWheelServices"
import {message} from "antd"
import {refreshAccessToken} from "../../utils/webview"
import LuckyWheelRewardModal from "../LuckyWheelReward/LuckyWheelRewardModal"
import WidgetBar from "../../components/WidgetBar"

const Wheel = (props) => {
    const {freeSpin, luckyWheel, user, refetch, loadingFetch, setRefetch, setFreeSpin, gameToken, setGameToken} = props
    const [loading, setLoading] = useState(false)
    const [reward, setReward] = useState({})
    const [openReward, setOpenReward] = useState(false)
    const [currentDeg, setCurrentDeg] = useState(0)
    const [showWidget, setShowWidget] = useState(true)
    const [index, setIndex] = useState(null)

    const toggleReward = (open) => setOpenReward(open)

    const openMessage = () => {
        message.error({
            key: "unique",
            className: "message-error",
            content: "Insufficient token",
        })
        setTimeout(() => {
            message.error({
                key: "unique",
                content: "Insufficient token",
                duration: 2,
            })
        }, 1000)
    }

    const onSpin = async () => {
        if (gameToken < 5 && !freeSpin) {
            return openMessage()
        }

        const token = !freeSpin ? gameToken - 5 : gameToken
        // updateGameToken(token)
        setGameToken(token)
        setShowWidget(false)
        try {
            setLoading(true)
            let wheel = document.getElementById("inner-wheel")
            wheel.style.transition = "10s"
            wheel.style.transform = "rotate(" + 1080 + "deg)"
            const res = await spinWheel(index)
            const {data, success} = res

            if (success) {
                setReward(data)
                const totalDeg = 3600 - data.reward.deg + 18
                setCurrentDeg(totalDeg)
                wheel.style.transform = "rotate(" + totalDeg + "deg)"
                setTimeout(() => {
                    setLoading(false)
                    if (data?.reward?.type === "MFR") {
                        setGameToken(token + data?.reward?.value)
                    }
                    wheel.style.transition = "0s"
                    toggleReward(true)
                    setFreeSpin(data.free_spin)
                }, 12000)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            refreshAccessToken()
        }
    }

    const _renderImage = (type) => {
        let output
        switch (type) {
            case "MoonBox":
                output = <img src={gift} alt="" />
                break
            case "MFR":
                output = <img src={mfr} alt="" />
                break
            case "MFG":
                output = <img src={mfg} alt="" />
                break
            case "oMFG":
                output = <img src={oMFG} alt="" />
                break
            case "WeeklyRaffleTicket":
                output = <img src={raffleTicket} alt="" />
                break
            case "MoonFitAstarDegensTicket":
                output = <img src={astarTicket} alt="" />
                break
            case "MoonFitGLMRApesTicket":
                output = <img src={apesTicket} alt="" />
                break
            case "MoonFitMonesTicket":
                output = <img src={monesTicket} alt="" />
                break
            case "MoonFitAjunaNetworkTicket":
                output = <img src={ajunaTicket} alt="" />
                break
            case "FreeSpin":
                output = <img src={spinTicket} alt="" />
                break
            case "MoonBoxSlot":
                output = <img src={moonboxSlot} alt="" />
                break
            default:
                output = <img src={`https://cdn.moonfit.xyz/image/original/assets/icons/${type}.png`} alt="" />
                break
        }
        return output
    }

    useEffect(() => {
        openReward &&
            setTimeout(() => {
                let wheel = document.getElementById("inner-wheel")
                if (wheel) {
                    wheel.style.transform = "rotate(" + (currentDeg - 3600) + "deg)"
                }
            }, 1000)
        setShowWidget(!openReward)
    }, [openReward])

    const onChangeIndex = (e) => {
        setIndex(e.target.value)
    }

    // const env = process.env.REACT_APP_ENV

    return (
        <div className="wheel-container">
            <div className={showWidget ? "show" : "hide"}>
                <WidgetBar gameToken={gameToken} redirectTo={"Lucky_wheel"} />
            </div>
            <LuckyWheelRewardModal
                value={reward?.reward?.value}
                type={reward?.reward?.type}
                is_pending={reward?.moon_box?.is_pending}
                need_buy_slot={reward?.moon_box?.need_buy_slot}
                buy_slot_fee={reward?.moon_box?.buy_slot_fee}
                lucky_box_id={reward?.moon_box?.id}
                rarity={reward?.moon_box?.rarity}
                unit={reward?.reward?.unit}
                userToken={reward?.user?.game_token}
                user={user}
                open={openReward}
                refetch={refetch}
                setRefetch={setRefetch}
                toggle={toggleReward}
                className="reward-modal-container"
            />
            <div className="flex flex-col justify-between wheel-flex">
                <div></div>
                <div className="wheel-wrapper" id="wheel-wrapper">
                    <div id="direction">
                        <div id="direction-inner"></div>
                    </div>
                    <div id="sub-direction"></div>
                    <div className="wheeldotsround flicker" id="wheeldotsround">
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className="wheeldots"></div>
                        <div className={`wheel wheel-${luckyWheel.length}`} id="outer-wheel">
                            <div id="inner-wheel">
                                {luckyWheel &&
                                    luckyWheel.map((wheel, index) => {
                                        return (
                                            <div key={index} className={`sec ${wheel.type}`}>
                                                {_renderImage(wheel.type)}
                                                <span>{wheel.value}</span>
                                            </div>
                                        )
                                    })}
                            </div>

                            <a id="spin" className={`${loading ? "disabled" : ""}`} onClick={onSpin}>
                                <div id="inner-spin"></div>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={`lw-button text-center${loadingFetch ? " opacity-0" : " opacity-100	"}`}>
                    {/* {env === "development" && (
                        <div className="d-flex">
                            <input value={index || ""} onChange={onChangeIndex} />
                        </div>
                    )} */}

                    {freeSpin !== 0 && (
                        <>
                            <div className="button-3d">
                                <a className={`text-[14px] ${loading ? "disabled" : ""}`} onClick={onSpin}>
                                    <img className="pb-1 mt-1 mr-2" src={lottery} alt="" />
                                    <span>You have {freeSpin || 1} free spin</span>
                                </a>
                            </div>
                            <p className="text-[13px] normal-case no-event mx-auto leading-5 opacity-70 font-medium">
                                Come back everyday for a free spin
                            </p>
                        </>
                    )}
                    {freeSpin === 0 && (
                        <div className="button-3d">
                            <a className={loading ? "disabled" : ""} onClick={onSpin}>
                                <img className="pb-1 mr-2" src={mfrWhite} alt="" />
                                <span>5</span>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Wheel
