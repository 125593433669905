import React, {useEffect, useState} from "react"
import UserWelcome from "./UserWelcome"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import {ACTION_TYPES} from "../../common/constants"
import {postActionToMobile} from "../../utils/webview"


export default function UserWelcomeContainer() {
    const [numberRetry, setNumberRetry] = useState(1)
    const [components,setComponents] = useState({});
    const [visitorId, setVisitorId] = useState("")
    const [err, setErr] = useState(false)
    const captureFingerprint = async () => {
        try {
            const fp = await FingerprintJS.load()
            const result = await fp.get()
            // Access the fingerprint ID
            const fingerprint = result.visitorId
            // Do something with the fingerprint data, such as sending it to a server for authentication or storing it locally.
            setVisitorId(fingerprint)
            setComponents(JSON.stringify(result.components))
            setErr(false)
        } catch (err) {
            setErr(true)
            setVisitorId("")
            setComponents({})
        }
    }
    useEffect(() => {
        captureFingerprint()
    }, [])
    useEffect(() => {
        if (visitorId !== "") {
            postActionToMobile(ACTION_TYPES.SEND_USER_ID, {visitorId,components})
        } else if (err === true) {
            console.log(numberRetry)
            if (numberRetry === 5) {
                postActionToMobile(ACTION_TYPES.SEND_USER_ID, null)
                return
            }
            setNumberRetry(numberRetry + 1)
            captureFingerprint()
        }
    }, [visitorId, numberRetry])

    return (
        <>
            {visitorId !== "" ? (
                <UserWelcome />
            ) : (
                <div className="loading">
                    <p>Loading...</p>
                </div>
            )}
        </>
    )
}

