import {message} from "antd"
import React, {useEffect, useState} from "react"
import btnSpin from "../../assets/images/icons/btn-spin.png"
import cap from "../../assets/images/icons/paris-dot-cap.svg"
import cup from "../../assets/images/icons/cup.svg"
import keychain from "../../assets/images/icons/keychain.svg"
import mfr from "../../assets/images/icons/mfr-border.svg"
import bag from "../../assets/images/icons/bag.svg"
import tShirt from "../../assets/images/icons/t-shirt.svg"
import wheelInner from "../../assets/images/icons/wheel-inner.png"
import WidgetBar from "../../components/WidgetBar"
import {spinSpecialWheel} from "../../services/LuckyWheelServices"
import {refreshAccessToken} from "../../utils/webview"
import LuckyWheelRewardPolkadotModal from "../LuckyWheelReward/LuckyWheelRewardPolkadotModal"

const Wheel = (props) => {
    const {
        luckyWheel,
        user,
        refetch,
        loadingFetch,
        setRefetch,
        setFreeSpin,
        gameToken,
        setOnKeyboardShowing,
        isUserClaim,
        eventCodes,
        setIsUserClaim,
    } = props
    const [loading, setLoading] = useState(false)
    const [reward, setReward] = useState({})
    const [openReward, setOpenReward] = useState(false)
    const [currentDeg, setCurrentDeg] = useState(0)
    const [showWidget, setShowWidget] = useState(true)
    const [code, setCode] = useState("")

    const toggleReward = (open) => setOpenReward(open)

    const openMessage = (content) => {
        message.error({
            key: "unique",
            className: "message-error",
            content,
        })
        setTimeout(() => {
            message.error({
                key: "unique",
                content,
                duration: 2,
            })
        }, 1000)
    }

    const onSpin = async () => {
        if (!code) {
            setTimeout(() => {
                openMessage("Code is required")
            }, 300)
            return
        }
        const lowerCaseEventCodes = eventCodes.map((element) => element.toLowerCase())
        const lowerCaseCode = code.toLowerCase()
        if (!lowerCaseEventCodes.includes(lowerCaseCode)) {
            setTimeout(() => {
                openMessage("Code is invalid")
            }, 300)
            return
        }
        if (isUserClaim) {
            setTimeout(() => {
                openMessage("This code has been used")
            }, 300)
            return
        }
        if (loading) return
        try {
            setLoading(true)
            let wheel = document.getElementById("inner-wheel")
            wheel.style.transition = "10s"
            wheel.style.transform = "rotate(" + 1080 + "deg)"
            const res = await spinSpecialWheel("polkadot_decoded_2023", {code})
            const {data, success, message} = res

            if (success) {
                setShowWidget(false)
                setReward(data)
                const totalDeg = 3600 - data.reward.deg + 18
                setCurrentDeg(totalDeg)
                wheel.style.transform = "rotate(" + totalDeg + "deg)"
                setIsUserClaim(true)
                setTimeout(() => {
                    setLoading(false)
                    wheel.style.transition = "0s"
                    toggleReward(true)
                    setFreeSpin(data.free_spin)
                }, 12000)
            } else {
                setLoading(false)
                openMessage(message)
            }
        } catch (error) {
            setLoading(false)
            refreshAccessToken()
        }
    }

    const _renderImage = (type) => {
        let output
        switch (type) {
            case "MerchandiseCap":
                output = <img src={cap} alt="" />
                break
            case "MFR":
                output = <img src={mfr} alt="" />
                break
            case "MerchandiseTShirt":
                output = <img src={tShirt} alt="" />
                break
            case "MerchandiseKeychain":
                output = <img src={keychain} alt="" />
                break
            case "MerchandiseCup":
                output = <img src={cup} alt="" />
                break
            case "MerchandiseToteBag":
                output = <img src={bag} alt="" />
                break
            default:
                break
        }
        return output
    }

    const onChangeCode = (e) => {
        const formattedValue = e.target.value.trim()
        setCode(formattedValue)
    }

    useEffect(() => {
        openReward &&
            setTimeout(() => {
                let wheel = document.getElementById("inner-wheel")
                if (wheel) {
                    wheel.style.transform = "rotate(" + (currentDeg - 3600 + 18) + "deg)"
                }
            }, 1000)
        setShowWidget(!openReward)
        setCode("")
    }, [openReward])

    const onInputUp = (e) => {
        if (e.keyCode === 13) {
            const el = document.getElementById("spin-code")
            el && el.blur()
        }
    }

    useEffect(() => {
        window.addEventListener("resize", checkPopState)
        return () => window.removeEventListener("resize", checkPopState)
    }, [])

    const checkPopState = () => {
        setOnKeyboardShowing(false)
    }

    return (
        <div className="wheel-container">
            <div className={showWidget ? "show" : "hide"}>
                <WidgetBar gameToken={gameToken} redirectTo={"Lucky_wheel"} />
            </div>
            <LuckyWheelRewardPolkadotModal
                value={reward?.reward?.value}
                type={reward?.reward?.type}
                is_pending={reward?.moon_box?.is_pending}
                need_buy_slot={reward?.moon_box?.need_buy_slot}
                buy_slot_fee={reward?.moon_box?.buy_slot_fee}
                lucky_box_id={reward?.moon_box?.id}
                rarity={reward?.moon_box?.rarity}
                unit={reward?.reward?.unit}
                userToken={reward?.user?.game_token}
                user={user}
                open={openReward}
                refetch={refetch}
                setRefetch={setRefetch}
                toggle={toggleReward}
                className="reward-modal-container polkadot"
            />
            <div className="flex flex-col justify-between wheel-flex">
                <div></div>
                <div className="wheel-wrapper" id="wheel-wrapper">
                    <div id="direction">
                        <div id="direction-inner"></div>
                    </div>
                    <div id="sub-direction"></div>
                    <div className="wheelsquaresround" id="wheelsquaresround">
                        <div className="wheelsquares-circle"></div>
                        {Array.from(Array(17).keys()).map((item) => (
                            <div className="wheelsquares" key={item}></div>
                        ))}
                        <div className={`wheel wheel-${luckyWheel.length || 10}`} id="outer-wheel">
                            <div id="inner-wheel">
                                {luckyWheel &&
                                    luckyWheel.map((wheel, index) => {
                                        return (
                                            <div key={index} className={`sec ${wheel.type}`}>
                                                {_renderImage(wheel.type)}
                                                <span>{wheel.value}</span>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <a id="spin-beyond" className={`${loading ? "disabled" : ""}`}>
                            <img src={wheelInner} alt="" />
                        </a>
                    </div>
                </div>

                <div className="nonesense-block opacity-0">This is a block was created for nothing</div>
                <div className={`lw-bottom text-center${loadingFetch ? " opacity-0" : " opacity-90"}`}>
                    <div className="lw-spin-wrapper flex">
                        <input
                            value={code}
                            type="text"
                            id="spin-code"
                            className="lw-code block w-10/12"
                            placeholder="Your code"
                            onChange={onChangeCode}
                            autoComplete="off"
                            onKeyUp={onInputUp}
                            onFocus={() => setOnKeyboardShowing(true)}
                            onBlur={() => setOnKeyboardShowing(false)}
                        />
                        <div className="btn-spin-wrapper absolute">
                            <a onClick={onSpin}>
                                <img className="button-box-shadow-2" src={btnSpin} alt="" />
                            </a>
                        </div>
                    </div>
                    <p className="text-[13px] normal-case no-event mx-auto leading-5 opacity-70 font-medium w-3/4">
                        Only for exclusive attendance to the Polkadot Decoded 2023 Satellite event
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Wheel
