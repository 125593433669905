import api from "../common/config/axios"

export const getWeekylyRaffleData = async (round) => {
    const {data} = await api.get(`missions/weekly-raffle${round ? `/${round}` : ""}`)
    return data
}

export const getRaffleData = async (raffle = '') => {
    const {data} = await api.get(`missions/${raffle}`)
    return data
}
