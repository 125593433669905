import {LOCALSTORAGE_KEY, setLocalStorage} from "./LocalStorage"
import {ACTION_TYPES} from "../common/constants"

export const postActionToMobile = (action, data, callback) => {
    console.info("postActionToMobile:", {action, data})
    if (window.messageHandler) {
        window.messageHandler.postMessage(
            JSON.stringify({
                action,
                data,
            })
        )
        return
    }

    console.info("No callback messageHandler", {action, data})

    if (typeof callback === "function") {
        callback()
    }
}

export const refreshAccessToken = () => {
    postActionToMobile(ACTION_TYPES.REFRESH_ACCESS_TOKEN, {callback: "accessTokenUpdate"})
}

export const expiredAccessToken = () => {
    postActionToMobile(ACTION_TYPES.EXPIRED_ACCESS_TOKEN, {})
}

export const accessTokenUpdate = (data) => {
    // console.log(data)
    if (!data || !data.token) {
        // alert("Params invalid: " + JSON.stringify(data))
        return
    }

    // alert("Update access token: " + data.token)
    setLocalStorage(LOCALSTORAGE_KEY.ACCESS_TOKEN, data.token)
}

export const updateGameToken = (token) => {
    const gameToken = Math.floor(token * 100) / 100

    postActionToMobile(ACTION_TYPES.UPDATE_GAME_TOKEN, {game_token: gameToken})
}

export const gotoMobilePage = (page) => {
    postActionToMobile(ACTION_TYPES.REDIRECT_PAGE, {page})
}

export const accessFromWeeklyRaffle = () => {
    postActionToMobile(ACTION_TYPES.ACCESS_WEEKLY_FROM_RAFFLE, {access: true})
}

export const openMobileBrowser = (url) => {
    postActionToMobile(ACTION_TYPES.OPEN_BROWSER, {url})
}

export const sendUserId = (id) => {
    postActionToMobile(ACTION_TYPES.SEND_USER_ID, {id})
}

/*
Actions:
{action: redirect_page, data: {page: 'daily_bonus'}}
{action: redirect_page, data: {page: 'lucky_wheel'}}
{action: update_game_token, data: {token: 11.1}}


{action: refresh_access_token, data: {callback: accessTokenUpdate}}
"accessTokenUpdate('{token: "${_access_token_}"}')"

 */
