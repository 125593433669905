import React from "react"
import Countdown from "react-countdown"

const CountdownComponent = ({ date, completedCallback = null }) => {
    if (!date) {
        return (<></>)
    }

    const CountdownItem = ({ time, timeTitle }) => {
        const formatZeroNumber = (number) => {
            return number >= 10 ? number : `0${number}`
        }

        return (
            <div className="countdown-item">
                <div className="countdown">
                    <div className="countdown-number">
                        <h3>{formatZeroNumber(time)}</h3>
                    </div>
                </div>
                <div className="countdown-title">
                    {/* {`${timeTitle}`} */}
                    {`${timeTitle}${time > 1 ? "s" : ""}`}
                </div>
            </div>
        )
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed && typeof completedCallback === 'function') {
            setTimeout(completedCallback, 350)
        }

        return (
            <>
                <CountdownItem time={days} timeTitle={"Day"} />
                <CountdownItem time={hours} timeTitle={"Hour"} />
                <CountdownItem time={minutes} timeTitle={"Min"} />
                {/* <CountdownItem time={seconds} timeTitle={"Sec"} /> */}
            </>
        )
    }
    return (
        <Countdown
            date={date}
            renderer={renderer}
            intervalDelay={1000}
        />
    )
}

export default CountdownComponent
