export const ACTION_TYPES = {
    REFRESH_ACCESS_TOKEN: 'refresh_access_token',
    EXPIRED_ACCESS_TOKEN: 'expired_access_token',
    REDIRECT_PAGE: 'redirect_page',
    UPDATE_GAME_TOKEN: 'update_game_token',
    OPEN_BROWSER: 'open_browser',
    TOGGLE_NAVBAR: 'toggle_navbar',
    POPUP: 'popup',
    ACCESS_WEEKLY_FROM_RAFFLE: 'access_from_weekly_raffle',
    HIDE_BUTTON: 'hide_button',
    BUTTON_GO_BACK: 'button_go_back',
    BUTTON_HISTORY: 'button_history',
    SEND_USER_ID: 'send_user_id',
}
    