export const INITIAL_WHEELS = [
    {
        "index": 0,
        "reward_type": "6_MFG",
        "type": "MFG",
        "value": 1,
        "ratio": {
            "from": 0,
            "to": 36
        }
    },
    {
        "index": 1,
        "reward_type": "9_WeeklyRaffleTicket",
        "type": "WeeklyRaffleTicket",
        "value": 1,
        "ratio": {
            "from": 36,
            "to": 72
        }
    },
    {
        "index": 2,
        "reward_type": "7_MFG",
        "type": "MFG",
        "value": 5,
        "ratio": {
            "from": 72,
            "to": 108
        }
    },
    {
        "index": 3,
        "reward_type": "5_MFR",
        "type": "MFR",
        "value": 100,
        "ratio": {
            "from": 108,
            "to": 144
        }
    },
    {
        "index": 4,
        "reward_type": "10_FreeSpin",
        "type": "FreeSpin",
        "value": 1,
        "ratio": {
            "from": 144,
            "to": 180
        }
    },
    {
        "index": 5,
        "reward_type": "2_MFR",
        "type": "MFR",
        "value": 5,
        "ratio": {
            "from": 180,
            "to": 216
        }
    },
    {
        "index": 6,
        "reward_type": "8_MFG",
        "type": "MFG",
        "value": 25,
        "ratio": {
            "from": 216,
            "to": 252
        }
    },
    {
        "index": 7,
        "reward_type": "3_MFR",
        "type": "MFR",
        "value": 10,
        "ratio": {
            "from": 252,
            "to": 288
        }
    },
    {
        "index": 8,
        "reward_type": "1_MoonBox",
        "type": "MoonBox",
        "value": 1,
        "ratio": {
            "from": 288,
            "to": 324
        }
    },
    {
        "index": 9,
        "reward_type": "4_MFR",
        "type": "MFR",
        "value": 50,
        "ratio": {
            "from": 324,
            "to": 360
        }
    }
]