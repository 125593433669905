import AOS from "aos"
import React, {useEffect} from "react"
import { useLocation } from "react-router-dom"
import router from "./router"
import {accessTokenUpdate} from "./utils/webview"

function App() {
    useEffect(() => {
        AOS.init({
            once: true,
            offset: 0,
        })
    }, [])

    window.accessTokenUpdate = accessTokenUpdate

    return <ScrollTop>{router}</ScrollTop>
}

const ScrollTop = ({children}) => {
    const pathname = useLocation()
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [pathname])
    return children
}

export default App
