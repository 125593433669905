import { LoadingOutlined } from "@ant-design/icons"
import { Avatar, Spin } from "antd"
import moment from "moment/moment"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import banner from "../../assets/images/background/apes-raffle-title.png"
import apesRewardTitle from "../../assets/images/background/apes-reward-title.png"
import dailyEvent from "../../assets/images/background/raffle-daily.png"
import lwEvent from "../../assets/images/background/raffle-lw.png"
import raffleTicket from "../../assets/images/icons/apes-ticket.svg"
import crown from "../../assets/images/icons/crown.svg"
import btnExclusive from "../../assets/images/materials/exclusive.png"
import btnHowToPlay from "../../assets/images/materials/how-to-play.png"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import numb3 from "../../assets/images/materials/numb3.png"
import users from "../../assets/images/materials/users.png"
import wallet from "../../assets/images/materials/wallet.png"
import btnWinners from "../../assets/images/materials/winners.png"
import CountdownComponent from "../../components/Countdown"
import WidgetBar from "../../components/WidgetBar"
import { getRaffleData } from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import { accessFromWeeklyRaffle, gotoMobilePage, refreshAccessToken } from "../../utils/webview"

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

const MoonFitApesRaffleContainer = () => {
    const navigate = useNavigate()
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
        beasts: {},
    })
    const [loadingChangeRound, setLoadingChangeRound] = useState(false)

    useEffect(() => {
        fetchWeeklyRaffe()
    }, [])

    const findWinner = (beasts = [], name = "") => {
        return beasts[0]
    }

    const fetchWeeklyRaffe = async () => {
        try {
            setLoadingChangeRound(true)
            const res = await getRaffleData('special-raffle/glmr_apes')
            const {data} = res
            setLoadingChangeRound(false)
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                beasts: findWinner(data.weekly_raffle.metadata.beasts),
                finishTime: data.weekly_raffle.finish_time,
            })
            updateState(data)
        } catch (error) {
            refreshAccessToken()
        }
    }

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    const onClickSpinNow = () => {
        accessFromWeeklyRaffle()
        return navigate("/lucky-wheel")
    }

    return (
        <div className="weekly-raffle-wrapper">
            <div className="weekly-raffle-container apes-event">
                <WidgetBar redirectTo="Weekly_Raffle" />
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="weekly-raffle-content">
                    <div className="weekly-raffle-banner-title">
                        {newestRound.prize ? (
                            <div className="text-center">
                                <div className="special-prize-bg">
                                    <img src={apesRewardTitle} alt="" />
                                </div>
                                <p className="text-[24px] race-sport-font text-[#EFAA5D] beast-reward mb-1">1 COMMON BEAST NFT</p>
                                <span>TO BE WON!</span>
                                <p className="text-white text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                                    You have{" "}
                                    <span className="text-[#A5D990] mx-1">
                                        {newestRound.tickets > 1
                                            ? `${newestRound.tickets} tickets`
                                            : `${newestRound.tickets} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        ) : null}

                        {!newestRound.isFinish && (
                            <div className="daily-countdown text-center mt-5 mb-5">
                                <p className="text-[16px] font-semibold leading-7 mb-1">
                                    Join <span className="text-[#A5D990]">GLMR Apes Clan</span>, complete <br />
                                    <span className="text-[#4CCBC9]">Daily Missions</span> or spin <br />
                                    <span className="text-[#F0489F]">The Lucky Wheel</span>
                                </p>
                                <p className="text-[11px] font-semibold leading-5">
                                    to earn GLMR Apes Tickets & a chance to win the prize pot!
                                </p>
                                <div className="flex flex-nowrap justify-center no-event">
                                    <CountdownComponent
                                        date={moment(newestRound.finishTime).utc().valueOf()}
                                        completedCallback={null}
                                    />
                                </div>
                                <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">
                                    until the next draw
                                </p>
                            </div>
                        )}
                    </div>
                    {!newestRound.isFinish && (
                        <div className="events grid grid-cols-2 gap-2">
                            <div className="lw-event" onClick={() => onClickSpinNow()}>
                                <img src={lwEvent} alt="" />
                            </div>
                            <div className="daily-event" onClick={() => gotoMobilePage("daily_bonus")}>
                                <img src={dailyEvent} alt="" />
                            </div>
                        </div>
                    )}

                    {newestRound.isFinish && (
                        <div className="finished-round-container">
                            <div className="floating-btn">
                                <img src={btnWinners} alt="" />
                            </div>
                            <div className="round-winner mt-2">
                                <div className="nft-winner mt-5 mb-5">
                                    <div className="uncommon-winner">
                                        <div className="uncommon-winner-border">
                                            <div className="winner-crown">
                                                <img src={crown} alt="" />
                                            </div>
                                            <div className="winner-beast">
                                                <img src={newestRound?.beasts?.user?.avatar} alt="" />
                                            </div>
                                        </div>
                                        <div className="winner-info text-center mt-2">
                                            <p className="text-[13px] text-[#ffffff] m-0">
                                                {newestRound?.uncommon?.user?.name}
                                            </p>
                                            <span className="text-[11px] text-[#90829D]">Common Beast NFT</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="winner-list my-3">
                                    {loadingChangeRound ? (
                                        <div className="text-center my-3">
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <ul
                                            className={`grid gap-1 ${
                                                raffle?.winners?.length > 0 ? "grid-cols-2" : "grid-cols-1"
                                            }`}
                                        >
                                            {raffle?.winners?.length > 0 ? (
                                                raffle?.winners?.map((winner, index) => (
                                                    <>
                                                        <li
                                                            key={`${index}_${new Date()}`}
                                                            className={`${
                                                                winner.id === newestRound.userId ? "active" : ""
                                                            }`}
                                                        >
                                                            <div className="winner-border">
                                                                <Avatar size={20} src={winner.avatar} />
                                                                <span className="text-[13px] ml-1 font-medium">
                                                                    {winner.name}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))
                                            ) : (
                                                <li className="text-center">
                                                    <span className="text-[13px] font-medium">
                                                        There are no winners this round
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="prize-pot">
                                <p className="text-[12px] font-semibold">PRIZE POT</p>
                                <p className="text-[16px] race-sport-font mb-1">200 $GLMR + 300 $GLMB</p>
                                <p className="text-[16px] race-sport-font text-[#EFAA5D] mb-1">1 COMMON BEAST NFT</p>
                                <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                    Total players this round:{" "}
                                    <span className="text-[#4CCBC9]">
                                        {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                    </span>
                                </p>
                                <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                    You have{" "}
                                    <span className="text-[#A5D990] mx-1">
                                        {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="instruction-container">
                        {/* <div className="button button-box-shadow w-[10.625rem]">How to play</div> */}
                        <div className="floating-btn">
                            <img src={btnHowToPlay} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb1} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">EARN TICKETS</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Join <span className="text-[#A5D990]">GLMR Apes Clan</span>, complete{" "}
                                    <span className="text-[#4CCBC9]">Daily Missions</span> or try your luck at{" "}
                                    <span className="text-[#E4007B]">The Lucky Wheel</span> to earn GLMR Apes Tickets
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb2} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">WAIT FOR THE DRAW</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    There will be ten (10) winners, in which 1 lucky winner win one{" "}
                                    <span className="text-[#EFAA5D]">(1) Common Beast NFT</span> and nine (9) winners
                                    share the <span className="text-[#F0489F]">$200 GLMR</span> &{" "}
                                    <span className="text-[#A5D990]">$300 GLMB</span> prize pot
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb3} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">CHECK FOR PRIZES</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Once the round’s over, come back to the page and check to see if you’ve won!
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="instruction-container exclusive">
                        <div className="floating-btn">
                            <img src={btnExclusive} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                For GLMR Apes & GLMR Jungle NFT Holders
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={wallet} alt="" />
                                </div>
                                <span className="text-[16px] race-sport-font font-semibold">01</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Keep your <span className="text-[#A5D990]">GLMR Apes</span> &{" "}
                                    <span className="text-[#A5D990]">GLMR Jungle NFT</span> in your wallet until the
                                    raffle is drawn to receive 3 valid tickets.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={users} alt="" />
                                </div>
                                <span className="text-[16px] race-sport-font font-semibold">02</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    You must be a member of the <span className="text-[#A5D990]">GLMR Apes Clan</span>{" "}
                                    until the drawing takes place to keep your 10 tickets rewards valid.
                                </p>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Failure to comply with these rules will result in invalid tickets.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoonFitApesRaffleContainer
