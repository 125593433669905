export const LOCALSTORAGE_KEY = {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    CURRENT_DATE: "CURRENT_DATE",
    WHEEL_REWARDS: "WHEEL_REWARDS"
}

export const getLocalStorage = (name, defaultValue = null) => {
    return localStorage.getItem(name) || defaultValue
}

export const setLocalStorage = (name, value) => {
    return localStorage.setItem(name, value)
}

export const removeLocalStorage = (name) => {
    return localStorage.removeItem(name)
}

export const getLocalStorageObject = () => {
    return localStorage
}
