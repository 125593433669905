import { LoadingOutlined } from "@ant-design/icons"
import { Avatar, Spin } from "antd"
import moment from "moment/moment"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import banner from "../../assets/images/background/welcome-raffle-banner.png"
import raffleTicket from "../../assets/images/icons/welcome-ticket.svg"
import btnHowToPlay from "../../assets/images/materials/how-to-play.png"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import numb3 from "../../assets/images/materials/numb3.png"
import btnWinners from "../../assets/images/materials/winners.png"
import CountdownComponent from "../../components/Countdown"
import WidgetBar from "../../components/WidgetBar"
import { getRaffleData } from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import { refreshAccessToken } from "../../utils/webview"

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />

const WelcomeRaffleContainer = () => {
    const navigate = useNavigate()
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
        common: {},
        uncommon: {},
    })
    const [loadingChangeRound, setLoadingChangeRound] = useState(false)

    useEffect(() => {
        fetchWeeklyRaffe()
    }, [])

    const fetchWeeklyRaffe = async () => {
        try {
            setLoadingChangeRound(true)
            const res = await getRaffleData('special-raffle/welcome_raffle')
            const {data} = res
            setLoadingChangeRound(false)
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                finishTime: data.weekly_raffle.finish_time,
            })
            updateState(data)
        } catch (error) {
            refreshAccessToken()
            setLoadingChangeRound(false)
        }
    }

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    return (
        <div className="weekly-raffle-wrapper">
            <div className="weekly-raffle-container welcome-raffle">
                <WidgetBar redirectTo="Weekly_Raffle" />
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="weekly-raffle-content">
                    <div className="weekly-raffle-banner-title">
                        {newestRound.prize ? (
                            <div className="text-center">
                                <p className="text-pink-glow text-[24px]">{newestRound.prize} $GLMR</p>
                                <span>TO BE WON!</span>
                                <p className="text-white text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                                    You have{" "}
                                    <span className="text-[#A5D990] mx-1">
                                        {newestRound.tickets > 1
                                            ? `${newestRound.tickets} tickets`
                                            : `${newestRound.tickets} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        ) : null}

                        <div className="daily-countdown text-center mt-5 mb-5">
                            <p className="text-[16px] font-semibold leading-7 mb-1">
                                Complete <span className="text-[#4CCBC9]">Welcome Tasks</span>
                            </p>
                            <p className="text-[11px] font-semibold leading-5">
                                to earn Raffle Tickets & a chance to win the prize pot!
                            </p>
                            <div className="flex flex-nowrap justify-center no-event">
                                <CountdownComponent
                                    date={moment(newestRound.finishTime).utc().valueOf()}
                                    completedCallback={null}
                                />
                            </div>
                        </div>
                    </div>

                    {newestRound.isFinish && (
                        <div className="finished-round-container">
                            <div className="floating-btn">
                                <img src={btnWinners} alt="" />
                            </div>
                            <div className="round-winner mt-2">
                                <div className="winner-list my-3">
                                    {loadingChangeRound ? (
                                        <div className="text-center my-3">
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <ul
                                            className={`grid gap-1 ${
                                                raffle?.winners?.length > 0 ? "grid-cols-2" : "grid-cols-1"
                                            }`}
                                        >
                                            {raffle?.winners?.length > 0 ? (
                                                raffle?.winners?.map((winner, index) => (
                                                    <>
                                                        <li
                                                            key={`${index}_${new Date()}`}
                                                            className={`${
                                                                winner.id === newestRound.userId ? "active" : ""
                                                            }`}
                                                        >
                                                            <div className="winner-border">
                                                                <Avatar size={20} src={winner.avatar} />
                                                                <span className="text-[13px] ml-1 font-medium">
                                                                    {winner.name}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))
                                            ) : (
                                                <li className="text-center">
                                                    <span className="text-[13px] font-medium">
                                                        There are no winners this round
                                                    </span>
                                                </li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="prize-pot">
                                <p className="text-[12px] font-semibold">PRIZE POT</p>
                                <p className="text-[24px] text-pink-glow mb-5">{raffle.prize || 0} $GLMR</p>
                                <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                    Total players this round:{" "}
                                    <span className="text-[#4CCBC9]">
                                        {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                    </span>
                                </p>
                                <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                    You have{" "}
                                    <span className="text-[#A5D990] mx-1">
                                        {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                    </span>{" "}
                                    <img className="mr-1" src={raffleTicket} alt="" /> this round
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="instruction-container">
                        <div className="floating-btn">
                            <img src={btnHowToPlay} alt="" />
                        </div>
                        <div className="text-center">
                            <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                                More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                            </p>
                        </div>
                        <div className="grid grid-cols-1">
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb1} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">EARN TICKETS</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Complete the Welcome Tasks to earn Raffle Tickets
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb2} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">WAIT FOR THE DRAW</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    An amount of more than 100 $GLMR will fill the prize pot. There will be 10% of users
                                    who join the raffle split the prize pot. The size of the prize pool will increase as
                                    more people participate.
                                </p>
                            </div>
                            <div className="instruction-item">
                                <div className="instruction-item-index">
                                    <img src={numb3} alt="" />
                                </div>
                                <span className="text-[12px] font-semibold">CHECK FOR PRIZES</span>
                                <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                    Once the round’s over, come back to the page and check to see if you’ve won!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeRaffleContainer
