import axios from "axios"

import { getLocalStorage } from "../../utils/LocalStorage"
import { COMMON_CONFIGS } from "./env"

const api = axios.create({
    baseURL: COMMON_CONFIGS.API_URL,
    headers: {
        "Content-Type": "application/json",
    },
})

api.interceptors.request.use(
    (config) => {
        const token = getLocalStorage("ACCESS_TOKEN")

        if (token) {
            config.headers["authorization"] = `Bearer ${token}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.defaults.validateStatus = (status) => {
    return true
}

export default api
