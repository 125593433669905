import { Avatar } from "antd"
import moment from "moment/moment"
import React, { useCallback, useEffect, useState } from "react"
import starfishEvent from "../../assets/images/background/starfish-event.png"
import banner from "../../assets/images/background/starfish-banner-title.png"
import btnHowToPlay from "../../assets/images/materials/how-to-join.png"
import numb1 from "../../assets/images/materials/numb1.png"
import numb2 from "../../assets/images/materials/numb2.png"
import btnWinners from "../../assets/images/materials/winners.png"
import CountdownComponent from "../../components/Countdown"
import WidgetBar from "../../components/WidgetBar"
import { getRaffleData } from "../../services/WeeklyRaffleServices"
import ThousandSeparate from "../../utils/ThousandSeparate"
import { openMobileBrowser, refreshAccessToken } from "../../utils/webview"

const MoonfitStarfishContainer = () => {
    const [ticket, setTicket] = useState(0)
    const [raffle, setRaffle] = useState({})
    const [winners, setWinners] = useState([])
    const [nftWinner, setNftWinner] = useState([])
    const [newestRound, setNewestRound] = useState({
        userId: "",
        prize: 0,
        tickets: 0,
        finishTime: null,
        isFinish: false,
        winner: null,
    })

    const fetchAdventRaffle = useCallback(async () => {
        try {
            const res = await getRaffleData(`special-raffle/moonfit-x-starfish-finance-challenge`)
            const { data } = res
            setNewestRound({
                userId: data.user_id,
                prize: data.weekly_raffle.prize,
                tickets: data.tickets,
                isFinish: data.weekly_raffle.is_finished,
                finishTime: data.weekly_raffle.finish_time,
                winner: data.weekly_raffle.winners[0] || null,
            })
            updateState(data)
            const listWinner = [...data?.weekly_raffle?.metadata?.beasts]
            setWinners(data?.weekly_raffle?.metadata?.astrWinner)
            setListNftWinner(listWinner, data.user_id)
        } catch (error) {
            refreshAccessToken()
            console.log({ error })
        }
    }, [])

    const setListNftWinner = (winners, userId) => {
        if (Array.isArray(winners) && winners.length > 0) {
            const winner = winners.find((winner) => winner.user_id === userId)
            const newWinners = [...winners]
            if (winner) {
                const winnerIndex = newWinners.findIndex((winner) => winner.user_id === userId)
                if (winnerIndex !== -1) {
                    newWinners.splice(winnerIndex, 1)
                }
                newWinners.unshift(winner)
            }
            setNftWinner(newWinners)
        }
    }

    useEffect(() => {
        fetchAdventRaffle()
    }, [fetchAdventRaffle])

    const updateState = (data) => {
        setRaffle(data.weekly_raffle)
        setTicket(data.tickets)
    }

    const starfishEventUrl =
        process.env.REACT_APP_ENV === "development"
            ? `https://dev-event.moonfit.xyz/special-event/moonfit-x-starfish-finance-challenge`
            : `https://event.moonfit.xyz/special-event/moonfit-x-starfish-finance-challenge`

    const userWinBeast = nftWinner.find((winner) => winner?.user_id === newestRound.userId) || {}

    return (
        <div className="weekly-raffle-container starfish-raffle">
            <WidgetBar redirectTo="Weekly_Raffle" />
            <div className="weekly-raffle-content">
                <div className="weekly-raffle-banner no-event">
                    <img src={banner} alt="" />
                </div>
                <div className="text-center">
                    <p className="text-[#000000] text-[13px] font-medium mt-2 font-poppins flex flex-nowrap justify-center">
                        You have{" "}
                        <span className="text-[#E967E0] font-medium mx-1">
                            {newestRound.tickets > 1
                                ? `${newestRound.tickets} tickets`
                                : `${newestRound.tickets} ticket`}
                        </span>{" "}
                        this week
                    </p>
                </div>
                <div className="weekly-raffle-banner-title">
                    <div className="daily-countdown text-center mt-5 mb-5">
                        <p className="text-white text-[16px] font-semibold leading-7 mb-1">
                            Embrace the MoonFit x Starfish Finance Challenge to grab a chance to get exclusive rewards!
                        </p>
                        <p className="text-[#FFB044] text-[16px] font-semibold leading-7 mb-1">
                            3 MoonFit Common NFTs + 3000 $ASTR + 10,000 Testnet $SEAN
                        </p>
                        <div className="flex flex-nowrap justify-center no-event">
                            <CountdownComponent
                                date={moment(newestRound.finishTime).utc().valueOf()}
                                completedCallback={null}
                            />
                        </div>
                        <p className="text-[11px] text-[#90829D] leader-[1.125rem] font-medium">until the next draw</p>
                    </div>
                </div>

                <div className="events grid">
                    <div className="lw-event">
                        <a onClick={() => openMobileBrowser(starfishEventUrl)}>
                            <img src={starfishEvent} alt="" />
                        </a>
                    </div>
                </div>

                {newestRound.isFinish && newestRound.winner && (
                    <div className="finished-round-container">
                        <div className="floating-btn">
                            <img src={btnWinners} alt="" />
                        </div>

                        <div className="round-winner mt-2">
                            {userWinBeast.user_id && (
                                <div className="nft-winner mt-5 mb-5">
                                    <div
                                        className={`${userWinBeast.rarity_name === "Common"
                                                ? "common-winner-border"
                                                : "other-winner-border"
                                            }`}
                                    >
                                        <div className="winner-beast">
                                            <img src={userWinBeast?.user?.avatar} alt="" />
                                        </div>
                                    </div>
                                    <div className="winner-info text-center mt-2">
                                        <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                            {userWinBeast?.user?.name}
                                        </p>
                                        <span className="text-[11px] text-[#90829D]">
                                            {userWinBeast.rarity_name === "Common"
                                                ? "Common Beast NFT"
                                                : "Rare AAA NFT"}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className="nft-winners mt-5 mb-5">
                                {nftWinner
                                    .filter((winner) => winner.user_id !== newestRound.userId)
                                    .map((winner) => (
                                        <div className="common-winner mt-3">
                                            <div
                                                className={`${winner.rarity_name === "Common"
                                                        ? "common-winner-border"
                                                        : "other-winner-border"
                                                    }`}
                                            >
                                                <div className="winner-beast">
                                                    <img src={winner?.user?.avatar} alt="" />
                                                </div>
                                            </div>
                                            <div className="winner-info text-center mt-2">
                                                <p className="text-[13px] text-[#ffffff] leading-3 mt-2 mb-0">
                                                    {winner?.user?.name}
                                                </p>
                                                <span className="text-[11px] text-[#90829D]">
                                                    {winner.rarity_name === "Common"
                                                        ? "Common Beast NFT"
                                                        : winner.rarity_name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="winner-list my-3">
                                <ul className={`grid gap-1 ${winners.length > 0 ? "grid-cols-2" : "grid-cols-1"}`}>
                                    {winners.length > 0 ? (
                                        <>
                                            {winners.map((winner, index) => (
                                                <li
                                                    key={`${index}_${new Date()}`}
                                                    className={`${winner.id === newestRound.userId ? "active" : ""}`}
                                                >
                                                    <div className="winner-border">
                                                        <Avatar size={20} src={winner.avatar} />
                                                        <span className="text-[13px] ml-1 font-medium">
                                                            {winner.name}
                                                        </span>
                                                    </div>
                                                </li>
                                            ))}
                                        </>
                                    ) : (
                                        <li className="text-center">
                                            <span className="text-[13px] font-medium">
                                                There are no winners this round
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <div className="prize-pot">
                            <p className="text-[12px] font-semibold">PRIZE POT</p>
                            <p className="text-[16px] race-sport-font text-[#EFAA5D] mb-3">3 MoonFit Common NFTs</p>
                            <p className="text-[16px] race-sport-font text-[#57C2FF] mb-3">+ 3000 $ASTR</p>
                            <p className="text-[16px] race-sport-font text-[#57C2FF] mb-3">+ 10,000 Testnet $sean</p>
                            <p className="text-[#90829D] text-[13px] leading-5 font-medium mb-1">
                                Total players this round:{" "}
                                <span className="text-[#4CCBC9]">
                                    {raffle.total_player ? ThousandSeparate(raffle.total_player) : "0"}
                                </span>
                            </p>
                            <p className="text-[#90829D] font-medium text-[13px] leading-5 flex flex-nowrap">
                                You have{" "}
                                <span className="text-[#57C2FF] mx-1">
                                    {ticket > 1 ? `${ticket} tickets` : `${ticket} ticket`}
                                </span>{" "}
                                this round
                            </p>
                            {/* {newestRound?.userId === newestRound?.winner?.id && (
                                <a onClick={() => openMobileBrowser("https://forms.gle/FrqDr6j9HcwWumtV7")}>
                                    <img src={btnClaim} alt="" />
                                </a>
                            )} */}
                        </div>
                    </div>
                )}

                <div className="instruction-container">
                    <div className="floating-btn">
                        <img src={btnHowToPlay} alt="" />
                    </div>
                    <div className="text-center">
                        <p className="text-[13px] text-[#90829D] font-semibold leading-5 mt-3">
                            More Raffle Tickets, higher chance of winning (and of course, higher rewards)!
                        </p>
                    </div>
                    <div className="grid grid-cols-1">
                        <div className="instruction-item">
                            <div className="instruction-item-index">
                                <img src={numb1} alt="" />
                            </div>
                            <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                Download <span className="text-[#E4007B]">Moonfit app</span> & Connect your{" "}
                                <span className="text-[#ffffff]">MetaMask wallet.</span>
                            </p>
                        </div>
                        <div className="instruction-item">
                            <div className="instruction-item-index">
                                <img src={numb2} alt="" />
                            </div>
                            <p className="text-[13px] text-[#90829D] font-medium leading-5">
                                Complete daily mandatory tasks to get daily random reward and tickets to the{" "}
                                <span className="text-[#ffffff]">Master Prize Pool</span>. The more tickets they have,
                                the more likely they are to receive rewards from{" "}
                                <span className="text-[#ffffff]">Master Prize Pool.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoonfitStarfishContainer
