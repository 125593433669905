import api from "../common/config/axios"

export const getLuckyWheelInfo = async () => {
    const {data} = await api.get(`missions/lucky-wheel`)
    return data
}

export const spinWheel = async (index) => {
    const apiUri = index ? `missions/lucky-wheel/spin?index=${index}` : `missions/lucky-wheel/spin`
    const {data} = await api.post(apiUri)
    return data
}

export const buyMoreSlot = async (payload) => {
    const {data} = await api.post(`lucky-box/buy-slot-2`, payload)
    return data
}

export const getSpecialLuckyWheelInfo = async (slug) => {
    const {data} = await api.get(`missions/special-lucky-wheel/${slug}`)
    return data
}

export const spinSpecialWheel = async (slug, payload) => {
    const {data} = await api.post(`missions/special-lucky-wheel/${slug}/spin`, payload)
    return data
}

export const clearCode = async () => {
    const {data} = await api.post(`dev/special-lucky-wheel/beyond_the_chain_2023`)
    return data
}
