import {message} from "antd"
import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {INITIAL_WHEELS} from "../../common/constants/wheels"
// import {getLuckyWheelInfo} from "../../services/LuckyWheelServices"
import {LOCALSTORAGE_KEY, setLocalStorage} from "../../utils/LocalStorage"

const AppRedirectContainer = () => {
    const navigate = useNavigate()
    const search = window.location.search
    const params = new URLSearchParams(search)
    useEffect(() => {
        const token = params.get("token")
        let action = params.get("action") || ""

        if (token) {
            setLocalStorage(LOCALSTORAGE_KEY.ACCESS_TOKEN, token)
            setLocalStorage(LOCALSTORAGE_KEY.CURRENT_DATE, new Date().getDate())
            fetchLuckyWheelInfo()
            action = /^\//.test(action) ? action : `/${action}`
            navigate(action, {replace: true})
        } else {
            message.open({
                type: "error",
                content: "Authentication failed!",
            })
        }
    }, [])

    const fetchLuckyWheelInfo = async () => {
        // const {data, success} = await getLuckyWheelInfo()
        // if (success) {
        //     setLocalStorage(LOCALSTORAGE_KEY.WHEEL_REWARDS, JSON.stringify(data.wheels))
        // }
        setLocalStorage(LOCALSTORAGE_KEY.WHEEL_REWARDS, JSON.stringify(INITIAL_WHEELS))
    }

    return <>Loading...</>
}

export default AppRedirectContainer
